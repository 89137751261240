import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import { withStyles } from "@material-ui/styles";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import {
  Button,
  CardMedia,
  Typography,
  Fade,
  Modal,
  CircularProgress,
  Box,
  FormControlLabel,
  Radio,
  Grid,
  RadioGroup,
  TextareaAutosize,
  createStyles,
} from "@material-ui/core";
import Stack from "@mui/material/Stack";
import CloseIcon from "@material-ui/icons/Close";
import Swal from "sweetalert2";
import { API } from "aws-amplify";
import ReactPlayer from "react-player";
import TimerIcon from "@material-ui/icons/Timer";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import config from "../../aws-exports";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { useHistory } from "react-router-dom";
import Rating from "@material-ui/lab/Rating";
import Tippy from "@tippyjs/react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import downloadPdfSvg from "../../components/svg/downloadpdf.png";
import { saveAs } from "file-saver";
import axios from "axios";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";
import FormativeQuiz, { formatTime } from "../Quiz/FormativeQuiz";
import SummativeQuiz from "../Quiz/SummativeQuiz";
import CertificatePopUp from "./CertificatePopUp";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import FormativeQuizWithFeedbackQuiz from "../Quiz/FormativeWithFeedbackQuiz";
import styled from "styled-components";
import FeedbackPopUp from "./FeedbackPopUp";
import FormativeQuizQuestionBank from "../Quiz/FormativeQuizQuestionBank";
import moment from "moment";
import { ReactComponent as Confetti } from "../../../src/assets/svg/Confetti.svg";
import { ReactComponent as ReverseConfetti } from "../../../src/assets/svg/ReverseConfetti.svg";
import { setViewAllArrData } from "../../redux/HomeData/HomeDataSlice";
import swal from "sweetalert";
const styles = () =>
  createStyles({
    h: {
      "&::before": {
        content: '"some content"',
        display: "block",
        height: 60,
        marginTop: -60,
      },
    },
  });
const GreenRadio = withStyles({
  root: {
    color: "#1C6D9C",
    overflow: "hidden",
    "&$checked": {
      color: "#1C6D9C",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
export const StyledButton = withStyles({
  root: {
    background:
      "linear-gradient(45deg, var(--color-button-background) 90%,var(--color-button-background) 30%)",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 38,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .2)",
    fontSize: "16px",
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);
const StyledButtonQuiz = withStyles({
  root: {
    background:
      "linear-gradient(45deg, var(--color-button-background) 90%, var(--color-button-background) 30%)",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 38,
    padding: "0 50px",
    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .2)",
    marginTop: "3rem",
    fontSize: "16px",
    marginBottom: "3rem",
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);
const StyledScoreContainer = styled.div`
  border-radius: 3%;
  background-color: #ffffff;
  width: 46rem;
  margin: auto;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.3);
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  .show-score-header {
    width: 170px;
    height: 160px;
    background-color: ${({ primary }) => (primary ? "#cdf5d5" : "#eacccc")};
    border-radius: 4%;
    align-items: center;
    text-align: center;
    margin-top: 3rem;
    justify-content: center;
  }
  @media only screen and (max-width: 500px) {
    width: 30rem;
    padding: 2rem;
  }
  @media only screen and (max-width: 370px) {
    width: 25rem;
  }
`;
export function TransitionsModal({
  setOpen,
  setCourseDetails,
  courseDetails,
  setCurNugget,
  setCurObject,
  setCurObjIndex,
  setCurNuggetId,
  setCurNugIndex,
  setCertificate,
  setProgress,
  setMarkComplete,
  setStartTime,
  setTpProgress,
  setcourseResume,
  scormpPause,
}) {
  let userDetails = useSelector(authData);
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [nextTopics, setNextTopics] = useState({});
  const { viewAllArr } = useSelector((state) => state.home);
  const handleOpen = () => {
    if (
      courseDetails.prerequisites == false ||
      courseDetails.prerequisites === "false"
    ) {
      startcourse();
    } else if (
      (courseDetails.prerequisites === true ||
        courseDetails.prerequisites === "true") &&
      courseDetails.prerequisitescourses?.length > 0
    ) {
      let preflg = 0;
      if (courseDetails.comp_topics !== undefined) {
        for (let i = 0; i < courseDetails.prerequisitescourses.length; i++) {
          if (
            courseDetails.comp_topics !== undefined &&
            !courseDetails.comp_topics.includes(
              courseDetails.prerequisitescourses[i].tid
            )
          ) {
            nextTopics.title = courseDetails.prerequisitescourses[i].ttitle;
            nextTopics.tid = courseDetails.prerequisitescourses[i].tid;
            setNextTopics(nextTopics);
            preflg = 1;
            break;
          }
        }
      } else {
        nextTopics.title = courseDetails.prerequisitescourses[0].ttitle;
        nextTopics.tid = courseDetails.prerequisitescourses[0].tid;
        setNextTopics(nextTopics);
        preflg = 1;
      }
      if (courseDetails.prerequisitescourses === undefined && preflg === 0) {
        preflg = 1;
      }
      if (preflg === 0) {
        startcourse();
      } else {
        var ptag = "";
        if (
          courseDetails.prerequisitestype !== undefined &&
          courseDetails.prerequisitestype == "Required"
        ) {
          for (let i = 0; i < courseDetails.prerequisitescourses.length; i++) {
            ptag =
              ptag +
              '<p class="classtags">' +
              courseDetails.prerequisitescourses[i].ttitle +
              "</p>";
            if (i === courseDetails.prerequisitescourses.length - 1) {
              Swal.fire(
                {
                  html:
                    '<p class="classtags1"> Please complete the below prerequisite course in order to enable you to start ' +
                    courseDetails.ttitle +
                    "</p>" +
                    "<br><br>",
                  icon: `warning`,
                  showConfirmButton: true,
                  denyButtonText: nextTopics.title,
                  confirmButtonText: "Cancel",
                  confirmButtonClass: "confirmClass",
                  buttonsStyling: false,
                  showDenyButton: true,
                  customClass: {
                    cancelButton: "order-2 ",
                    confirmButton: "order-3",
                    denyButton: "order-1 coursebtn",
                  },
                },
                function() {}
              ).then((result) => {
                if (result.isConfirmed) {
                } else if (result.isDenied) {
                  if (nextTopics.tid !== undefined) {
                    let breadcrumb = { ...userDetails };
                    breadcrumb.location = {
                      from: breadcrumb.location.from,
                      courseName: nextTopics.title,
                      prevLoc: breadcrumb.location.prevLoc,
                      programName: breadcrumb.location.programName,
                      topicName: breadcrumb.location.topicName,
                      topicId: breadcrumb.location.topicId,
                      viewAll: breadcrumb.location.viewAll,
                      programId: breadcrumb.location.programId,
                      cid: breadcrumb.location.cid,
                    };
                    dispatch(awsSignIn(breadcrumb));
                    history.push(`/course?id=${nextTopics.tid}`);
                    window.location.reload();
                  }
                }
              });
            }
          }
        } else if (
          courseDetails.prerequisitestype !== undefined &&
          courseDetails.prerequisitestype == "Suggestion"
        ) {
          for (let i = 0; i < courseDetails.prerequisitescourses.length; i++) {
            ptag =
              ptag +
              '<p class="classtags">' +
              courseDetails.prerequisitescourses[i].ttitle +
              "</p>";
            if (i === courseDetails.prerequisitescourses.length - 1) {
              Swal.fire({
                html:
                  ptag +
                  "</br>" +
                  "</br>" +
                  '<p style="color:#484848;">These are the recommended prerequisites for this course. Click "Cancel" below to start a prerequisite course, or click "OK to continue with this course.</p>',
                icon: `warning`,
                width: "400px",
                showCancelButton: true,
                cancelButtonText: "Cancel",
                showConfirmButton: true,
                confirmButtonText: "Ok",
                customClass: "sweetalert-lgs",
                confirmButtonClass: "confirmClass",
                buttonsStyling: false,
                cancelButtonClass: "cancelClass",
                allowOutsideClick: false,
              }).then((result) => {
                if (result.value) {
                  syncUserProgress();
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                }
              });
            }
          }
        } else {
          startcourse();
        }
      }
    } else {
      startcourse();
    }
  };
  function startcourse() {
    Swal.fire({
      title: `${userDetails?.languagejson?.Start_Course
        ?.Get_started_with_the_course || "Get started with the course?"}`,
      showDenyButton: true,
      showCancelButton: false,
      reverseButtons: true,
      icon: `warning`,
      denyButtonText: `${userDetails?.languagejson?.Start_Course?.No || "No"}`,
      confirmButtonText: `${userDetails?.languagejson?.Start_Course?.Yes ||
        "Yes"}`,
      allowOutsideClick: false,
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "confirmButton",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        syncUserProgress();
        let filteredArr = viewAllArr.filter(
          (obj) => obj.tid !== courseDetails?.tid
        );
        dispatch(setViewAllArrData(filteredArr));
      } else if (result.isDenied) {
      }
    });
  }
  async function syncUserProgress() {
    setLoading(true);
    const jsonString = JSON.stringify({
      oid: config.aws_org_id,
      logintype: "Cognito",
      status: "new",
      key: courseDetails.tid,
      id: userDetails.id,
      iid: config.aws_cognito_identity_pool_id,
      version: courseDetails.version,
      tnuggets: courseDetails.noofnuggets,
      nav: courseDetails.freenavigation,
      email: userDetails.username,
      emailid: userDetails.email,
      tenant: userDetails.uData.oid,
      pid: courseDetails.pid,
      vtid: courseDetails.vtid,
      tid: courseDetails.tid,
      sub_date: 1,
      ur_id: userDetails.uData.ur_id,
      schema: config.schema,
    });
    const base64EncodedString = btoa(jsonString);
    const bodyParam = {
      body: {
        json: base64EncodedString,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken,
      },
    };
    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateTopicReport",
        bodyParam
      );
      updateLocalUserData(courseDetails);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }
  function updateLocalUserData(courseDetails) {
    courseDetails.userdataset.userdata = true;
    courseDetails.userdataset.sd = Math.round(new Date().getTime() / 1000);
    var tp = "0";
    if (courseDetails.noofnuggets > 0) {
      tp = "1";
      for (var w = 0; w < courseDetails.noofnuggets; w++) {
        if (courseDetails.freenavigation) {
          tp = tp + "-" + "2";
        } else if (w == 0) {
          tp = tp + "-" + "1";
        } else {
          tp = tp + "-" + "0";
        }
      }
    }
    courseDetails.userdataset.tp = tp;
    courseDetails.userdataset.cobj = 0;
    courseDetails.userdataset.cvid = 0;
    courseDetails.userdataset.cdoc = 0;
    courseDetails.userdataset.cfq = 0;
    courseDetails.userdataset.csq = 0;
    courseDetails.userdataset.casmt = 0;
    courseDetails.nresume = {};
    courseDetails.nresume.rnugget = courseDetails.nuggets[0].nid;
    courseDetails.nuggets[0].objects[0].op = 1;
    if (courseDetails.freenavigation) {
      for (let index = 0; index < courseDetails.nuggets.length; index++) {
        if (courseDetails.nuggets[index].objects !== undefined) {
          for (
            let oindex = 0;
            oindex < courseDetails.nuggets[index].objects.length;
            oindex++
          ) {
            courseDetails.nuggets[index].objects[oindex].op = 2;
            if (
              index == courseDetails.nuggets.length - 1 &&
              oindex == courseDetails.nuggets[index].objects.length - 1
            ) {
              courseDetails.nuggets[index].objects[oindex].op = 1;
            }
          }
        }
      }
    }
    if (courseDetails.nuggets[0].objects[0].otype == "scorm") {
      scormpPause.current = false;
      var obj2 = {};
      obj2.ur_id = userDetails.uData?.ur_id;
      obj2.cid = courseDetails.tid;
      obj2.tid = courseDetails.tid;
      obj2.objid = courseDetails.nuggets[0].objects[0].oid;
      obj2.atype = 0;
      obj2.schema = config.schema;
      obj2.curObjIndex = 0;
      obj2.objlength = courseDetails.nuggets[0].objects.length - 1;
      obj2.curNugIndex = 0;
      obj2.nuggetlength = courseDetails.nuggets.length - 1;
      window.cudetails = obj2;
    }
    courseDetails.nresume.robject = 0;
    setTpProgress(tp);
    setCurNugget(courseDetails.nuggets[0]);
    setCurObject(courseDetails.nuggets[0].objects[0]);
    setCurObjIndex(0);
    setCurNuggetId(courseDetails.nuggets[0].nid);
    setCurNugIndex(0);
    setCertificate(false);
    setProgress(
      courseDetails?.nuggets[0]?.objects[0]?.rtime ??
        courseDetails?.nuggets[0]?.objects[0]?.oduration
    );
    setMarkComplete(false);
    setStartTime(new Date().getTime());
    setCourseDetails(courseDetails);
    setcourseResume(courseDetails.nresume);
    setOpen(true);
    setLoading(false);
  }
  return (
    <div>
      {loading && (
        <CircularProgress
          className="spinnerStyle"
          style={{ position: "absolute !important", left: "50%", top: "50%" }}
        />
      )}
      <StyledButton onClick={() => handleOpen()}>{`${userDetails?.languagejson
        ?.Course_View?.Start_Course || "Start course"}`}</StyledButton>
    </div>
  );
}
const defaultTippyProps = {
  interactive: true,
  interactiveBorder: 0,
  placement: "bottom-end",
  delay: [0, 0],
  maxWidth: 400,
  theme: "light",
  distance: 24,
};
export function ObjectPopUp({
  setOpen,
  open,
  setCourseDetails,
  courseDetails,
  curObject,
  setCurObject,
  curNugget,
  setCurNugget,
  curObjIndex,
  setCurObjIndex,
  progress,
  setProgress,
  markComplete,
  setMarkComplete,
  curNugIndex,
  setCurNugIndex,
  startTime,
  courseResume,
  setcourseResume,
  remaining,
  setRemaining,
  totalProgress,
  setTotalProgress,
  setTpProgress,
  perScore,
  setPerScore,
  totalScore,
  setTotalScore,
  showScore,
  setShowScore,
  startQuiz,
  setStartQuiz,
  loading,
  setLoading,
  isLoading,
  setIsLoading,
  certificate,
  setCertificate,
  updateCourseAnalytics,
  scormpPause,
  getTopicDetails,
  lastobject,
  extUrlType,
  setExtUrlType,
  extUrl,
  setExtUrl,
}) {
  let userDetails = useSelector(authData);
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0, 0, 0, 0.73)",
      overflow: "auto",
    },
    modalSection: {
      backgroundColor:
        userDetails?.themeDark === "dark"
          ? "#272C38"
          : theme.palette.background.paper,
      color:
        userDetails?.themeDark === "dark"
          ? theme.palette.background.paper
          : "black",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      height: "100%",
      borderRadius: ".8rem",
      overflowX: "hidden",
      overflowY: "auto",
      "@media (max-width: 500px)": {
        width: "90%!important",
      },
      "@media (max-width: 770px)": {
        padding: "1rem",
      },
    },
    paper1: {
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "400px",
      borderRadius: ".8rem",
      backgroundColor:
        userDetails?.themeDark === "dark"
          ? "#272C38"
          : theme.palette.background.paper,
      color:
        userDetails?.themeDark === "dark"
          ? theme.palette.background.paper
          : "black",
      "& > div > button > svg": {
        color:
          userDetails?.themeDark === "dark"
            ? theme.palette.background.paper
            : "black",
      },
      "@media (max-width: 430px)": {
        width: "90%",
      },
    },
    ratingroot: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      gap: "1.5rem",
      "& > span": {
        margin: 0,
      },
    },
    quizroot: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
    },
    MuiGriditem: {
      padding: "0 12px",
    },
    quizpaper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    certcover: {
      height: "100%",
    },
    overlay: {
      backgroundImage:
        "linear-gradient(to right bottom, rgba(0,0,0,.2), rgba(0,0,0,.2))",
      width: "1100px",
      height: "660px",
    },
    label: {
      "& .MuiTypography-body1": {
        fontSize: "1.3rem",
        color: "#555555",
        fontWeight: "500",
        overflow: "hidden !important",
      },
    },
    labelSurvey: {
      "& .MuiTypography-body1": {
        fontSize: "1.25rem",
        color: "#000",
        fontWeight: "600",
        overflow: "hidden !important",
      },
      "& .MuiTypography-body1::after": {
        content: "*",
        color: "red",
      },
    },
    label2: {
      "&.MuiFormControlLabel-root": {
        paddingLeft: "30px",
        display: "flex",
        flexDirection: "column",
      },
      "&.MuiTypography-body1": {
        fontSize: "1.3rem",
      },
    },
    contentType: {
      "& .PrivateSwitchBase-input-118": {
        "& ::after": {
          content: "hi !important",
        },
      },
    },
    barResponsive: {
      height: "25px",
      borderRight: "2px solid #000",
      marginLeft: "2rem",
      "@media (max-width: 500px)": {
        display: "none",
      },
    },
    infoResponsive: {
      display: "flex",
      height: "inherit",
      margin: "0 2rem",
      background: "transparent",
      border: "none",
      "@media (max-width: 500px)": {
        margin: "0rem 0.5rem",
      },
      "&:focus": {
        border: "2px solid black",
      },
    },
    closeResponsive: {
      border: "none",
      background: "transparent",
      cursor: "pointer",
      display: "flex",
      marginRight: "-20px",
      color:
        userDetails?.themeDark === "dark"
          ? theme.palette.background.paper
          : "black",
      "@media (max-width: 770px)": {
        marginRight: 0,
      },
      "&:focus": {
        border: "2px solid black",
      },
    },
    headerSection: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    mainHeader: {
      display: "flex",
      flexWrap: "wrap",
      fontWeight: "600",
      alignItems: "center",
      paddingRight: "4rem",
    },
    documentSection: {
      marginTop: "10px",
      width: "95%",
      height: "-webkit-fill-available",
      position: "absolute",
      marginBottom: "6rem",
      "@media (max-width: 330px)": {
        width: "100%",
      },
    },
    downloadIcon: {
      position: "absolute",
      right: "55px",
      background: "transparent",
      border: "none",
      height: "2.3rem",
      width: "2.3rem",
      marginLeft: "2rem",
      cursor: "pointer",
      zIndex: "10000",
      "@media (max-width: 400px)": {
        right: "40px",
      },
    },
    separator: {
      height: "27px",
      borderRight:
        userDetails?.themeDark === "dark"
          ? "2px solid lightgray"
          : "2px solid #333333",
      marginLeft: "2rem",
      "@media (max-width: 500px)": {
        display: "none",
      },
    },
    videoContainer: {
      display: "contents",
    },
    videoInnerContainer: {
      width: "90%!important",
      height: "88%!important",
      margin: "2rem auto 0rem!important",
      "@media (max-width: 700px)": {
        width: "100%!important",
        height: "70%!important",
      },
    },
    rateCourseButton: {
      fontSize: "1.5rem",
      fontWeight: "600",
      lineHeight: "1.6rem",
      "&:focus": {
        border: "2px solid black",
        color: "white",
      },
    },
    rateCourseSubmitButton: {
      fontWeight: "600",
      fontFamily: `myfontregular`,
      "&:focus": {
        border: "2px solid black",
        backgroundColor: "black",
        color: "white",
      },
    },
    pdfContainer: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      height: "88%",
      overflowX: "hidden",
      position: "relative",
      marginTop: "1rem",
      "& > div": {
        width: "100%",
        height: "100%",
        "& > iframe": {
          width: "100%",
          height: "100%",
          backgroundColor: "white",
        },
      },
    },
    closeButton: {
      border: "none",
      background: "transparent",
      cursor: "pointer",
      position: "relative",
      top: "-64rem",
      zIndex: "99",
      left: "87rem",
      padding: "12px",
    },
    totalQuestions: {
      fontSize: "1.5rem",
      margin: "0rem 1rem",
      color: userDetails?.themeDark === "dark" ? "lightgray" : "#555555",
    },
    scoreperQuestionStyle: {
      display: "flex",
      border: "1px solid #777",
      justifyContent: "center",
      alignItems: "center",
      width: "400px",
      borderRadius: "8px",
      backgroundColor: userDetails?.themeDark === "dark" ? "black" : "#f1f5f8",
      textAlign: "center",
      position: "relative",
    },
    allButtons: {
      marginTop: "3rem",
      display: "flex",
      gap: "2rem",
      flexWrap: "wrap",
      justifyContent: "center",
      "& > button": {
        width: "19rem",
      },
    },
    mainHeading: {
      "@media (max-width: 500px)": {
        fontSize: "2rem",
      },
      "@media (max-width: 370px)": {
        fontSize: "1.6rem",
      },
    },
  }));
  const classes = useStyles();
  const handle = useFullScreenHandle();
  const classesse = styles();
  const scroll = useRef();
  const history = useHistory();
  const reactplayer = useRef(null);
  const [cutOff, setCutOff] = useState(0);
  const [objStatus, setObjStatus] = useState(false);
  const [certificateList, setCertificateList] = useState({});
  const [certUrl, setCertUrl] = useState(null);
  const [showAnswers, setShowAnswers] = useState(false);
  const [surveyError, setSurveyError] = useState("");
  const [question, setQuestion] = useState([]);
  const closePopUp = useRef(false);
  const retake = useRef(false);
  const [scormnExtBtn, setScormnExtBtn] = useState(false);
  const [instructions, setInstructions] = useState({});
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [scorm_next_btn, setScorm_next_btn] = useState(false);
  const [mainSetOfQuestions, setMainSetOfQuestion] = useState({});
  const [certLoad, setCertLoad] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [totalMarks, setTotalMarks] = useState(0);
  const [timer, setTimer] = useState(curObject?.oduration);
  const [shouldShowCertificate, setShouldShowCertificate] = useState(false);
  const [timerPalyPause, setTimerPalyPause] = useState(() => {
    if (courseDetails.ctype === "enforced" && curObject.op === 1) {
      return true;
    }
    if (courseDetails.ctype === "enforced" && curObject.op === 2) {
      return false;
    }
    return false;
  });
  const nextCourseref = useRef(false);
  let questionsRef = useRef();
  const dispatch = useDispatch();
  React.useEffect(() => {
    let timer;
    if (timerPalyPause) {
      timer = setInterval(() => {
        setProgress((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(timer);
            if (curNugget.objects[curObjIndex].otype == "quiz") {
            } else {
              setMarkComplete(true);
            }
            setTimerPalyPause(false);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [timerPalyPause, progress]);
  useEffect(() => {
    if (
      courseDetails.otype === "regular" &&
      curObject.op === 1 &&
      (courseDetails.otype === "video" || courseDetails.otype === "audio")
    ) {
      setMarkComplete(false);
    } else if (courseDetails.ctype === "regular" && curObject.op === 1) {
      setMarkComplete(true);
    }
    if (curObject.op === 2) {
      setMarkComplete(true);
    }
  }, []);
  async function saveTimerInDatabase(updatedTimer) {
    let questionsJSON = {};
    questionsJSON.question = question;
    questionsJSON.cutoff = cutOff;
    questionsJSON.attempt = curObject?.attempt;
    questionsJSON.qtype = curObject?.qtype;
    questionsJSON.oduration = curObject?.oduration;
    questionsJSON.timer = curObject?.timer;
    questionsJSON.quiz_length = curObject?.quiz_length;
    questionsJSON.currentQuestion = currentQuestion;
    let obj = {};
    obj.json = questionsJSON;
    const jsonString = JSON.stringify({
      tid: courseDetails.tid,
      ur_id: userDetails?.uData?.ur_id,
      obj_id: curObject.oid,
      quiz_json: obj,
      time: updatedTimer,
      oid: config.aws_org_id,
      schema: config.schema,
    });
    const utf8String = unescape(encodeURIComponent(jsonString));
    const base64EncodedString = btoa(utf8String);
    const bodyParam = {
      body: {
        json: base64EncodedString,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken,
      },
    };
    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/savetimer",
        bodyParam
      );
      getTopicDetails();
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    CheckFeedbackSubmitted();
    getInstructions();
  }, []);
  async function CheckFeedbackSubmitted() {
    try {
      const requestBody = {
        tid: courseDetails.tid,
        oid: config.aws_org_id,
        ur_id: userDetails.uData.ur_id,
        schema: config.schema,
      };
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = `/CheckFeedbackSubmitted?json=${encodeURIComponent(
        base64EncodedString
      )}`;
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: userDetails?.accessToken,
          },
        }
      );
      setFeedbackSubmitted(response.body);
    } catch (error) {
      console.error(error);
    }
  }
  async function getInstructions() {
    try {
      const requestBody = {
        oid: config.aws_org_id,
        lang_code: userDetails?.uData?.lang,
        ur_id: userDetails.uData.ur_id,
        schema: config.schema,
      };
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = `/getInstructions?json=${encodeURIComponent(
        base64EncodedString
      )}`;
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: userDetails?.accessToken,
          },
        }
      );
      setInstructions(response);
    } catch (error) {
      console.error(error);
    }
  }
  const [openFeedback, setOpenFeedback] = useState(false);
  const handleOpenFeedback = () => {
    setOpenFeedback(true);
  };
  const handleModalClose = async () => {
    setShowScore(false);
    setIsRunning(false);
    setQuestion([]);
    if (
      curObject.otype == "scorm" &&
      (window.API?.LMSGetValue("cmi?.core.lesson_status") !== "completed" ||
        window.API?.LMSGetValue("cmi?.core.lesson_status") !== "passed") &&
      window.API?.LMSGetValue("cmi?.suspend_data")
    ) {
      updateUserScormAnalytics(1);
    }
    if (curObject.timer && config.aws_org_id !== "EXCELEARN") {
      saveTimerInDatabase(timer);
    }
    let getstatus = "";
    try {
      getstatus = window?.API?.LMSGetValue("cmi?.core.lesson_status");
    } catch (error) {
      try {
        getstatus = window?.API_1484_11.GetValue("cmi?.completion_status");
      } catch (error) {}
    }
    if (
      !certificate &&
      curObject.otype == "scorm" &&
      (courseDetails.certification == "true" ||
        courseDetails.certification === true) &&
      (getstatus === "completed" || getstatus === "passed") &&
      courseDetails.nuggets[curNugIndex].objects[curObjIndex].op != 2 &&
      curObjIndex == courseDetails.nuggets[curNugIndex].objects.length - 1 &&
      curNugIndex == courseDetails.nuggets.length - 1
    ) {
      syncUserCertificate("");
    } else {
      if (certificate) {
        await nextcourse();
      }
      setTimerPalyPause(false);
      setStartQuiz(false);
      questionsRef.current = [];
      setQuestion([]);
      setCurrentQuestion(0);
      setShowAnswers(false);
      setIsLoading(false);
      if (
        curObject.otype != "quiz" &&
        curObject.otype != "feedback" &&
        curObject.op == 1 &&
        courseDetails.ctype == "enforced" &&
        progress > 0
      ) {
        setOpen(false);
        updateResumeTime();
      } else if (
        curObject.otype != "quiz" &&
        curObject.otype != "feedback" &&
        curObject.op == 1 &&
        courseDetails.ctype == "enforced" &&
        progress <= 0
      ) {
        closePopUp.current = true;
        syncUserProgress(1);
      } else if (
        curObject.otype != "quiz" &&
        curObject.otype != "feedback" &&
        curObject.op == 1 &&
        courseDetails.ctype == "regular"
      ) {
        closePopUp.current = true;
        syncUserProgress(1);
      } else {
        setOpen(false);
      }
    }
    const cous = { ...courseDetails };
    if (curObject.timer && config.aws_org_id !== "EXCELEARN") {
      cous.nuggets[curNugIndex].objects[curObjIndex].rtime = timer;
    }
    setCourseDetails(cous);
    getTopicDetails();
    try {
      window.API.LMSTerminate();
    } catch (error) {
      try {
        window.API.Terminate();
      } catch (error) {}
    }
  };
  function renderObjects() {
    let objType = curObject.otype;
    if (objType !== undefined) {
      switch (objType.toLowerCase()) {
        case "video":
          return VideoView();
        case "audio":
          return AudioView();
        case "pdf":
          return PdfView();
        case "html":
          return WebView();
        case "interactivity":
          return WebView();
        case "scorm":
          return WebView();
        case "quiz":
          return tempView();
        case "feedback":
          return feedbackView();
        case "vimeo":
          return youtubeView();
        case "youtube":
          return youtubeView();
        case "pptx":
        case "word":
        case "spreadsheet":
          return pptView();
        default:
          return null;
      }
    }
  }
  const playVideoHandler = () => {
    if (courseDetails.ctype == "enforced" && curObject.op == 1) {
      setTimerPalyPause(true);
      if (objStatus === false) {
        setObjStatus(true);
      }
    }
    if (curObject.op == 2) {
      setMarkComplete(false);
    }
  };
  const pauseVideoHandler = () => {
    setProgress(progress);
    setTimerPalyPause(false);
  };
  function VideoView() {
    return (
      <div className={classes.videoContainer}>
        <ReactPlayer
          ref={(p) => {
            reactplayer.current = p;
          }}
          url={curObject.ourl}
          width="100%"
          height="80%"
          style={{ marginTop: "3rem" }}
          controls={true}
          className={classes.videoInnerContainer}
          onPlay={() => playVideoHandler()}
          onPause={() => pauseVideoHandler()}
          onDuration={() => {
            setMarkComplete(false);
            if (
              curObject.op == 1 &&
              courseDetails.userdataset !== undefined &&
              courseDetails.userdataset.tresume !== undefined &&
              reactplayer.current !== null
            ) {
              if (courseDetails.userdataset.tresume.oid == curObject.oid) {
                if (courseDetails.ctype == "enforced") {
                  setProgress(curObject?.rtime ?? curObject?.oduration);
                }
              }
            }
          }}
          onBuffer={() => console.log("onBuffer")}
          onStart={() => console.log("onStart")}
          config={{ file: { attributes: { controlsList: "nodownload" } } }}
          onContextMenu={(e) => e.preventDefault()}
          onEnded={() => {
            if (courseDetails.ctype == "regular" && curObject.op == 1) {
              setMarkComplete(true);
            } else if (curObject.op == 2) {
              setMarkComplete(true);
            } else if (courseDetails.ctype == "enforced" && curObject.op == 1) {
              setTimerPalyPause("play");
            }
          }}
        />
        <div className={markComplete && "overlay"}></div>
      </div>
    );
  }
  function AudioView() {
    return (
      <div>
        <div
          className="player-wrapper"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <CardMedia
            image={curObject.ourl}
            ref={(p) => {
              reactplayer.current = p;
            }}
            height="100%"
            component="audio"
            frameBorder={0}
            onPlay={() => playVideoHandler()}
            onPause={() => pauseVideoHandler()}
            onEnded={() => {
              if (courseDetails.ctype == "regular" && curObject.op == 1) {
                setMarkComplete(true);
              }
            }}
            controls
          />
        </div>
      </div>
    );
  }
  function youtubeView() {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          height: "90%",
        }}
      >
        <CardMedia
          image={
            curObject.otype !== "vimeo"
              ? `https://www.youtube.com/embed/${curObject.ourl}?rel=0&showinfo=0`
              : `https://player.vimeo.com/video/${curObject.ourl}?title=0&loop=0`
          }
          height="100%"
          component="iframe"
          onLoad={onDocumentLoadSuccess()}
          frameBorder={0}
          controls
        />
      </div>
    );
  }
  function onDocumentLoadSuccess() {
    if (courseDetails.ctype == "regular" && curObject.op == 1) {
      setMarkComplete(true);
    } else if (courseDetails.ctype == "enforced" && curObject.op == 1) {
      if (timerPalyPause != "play") {
        setTimerPalyPause("play");
      }
      if (objStatus === false) {
        setObjStatus(true);
      }
    } else if (curObject.op == 2) {
      setMarkComplete(true);
    }
  }
  function PdfView() {
    return (
      <div className={classes.pdfContainer}>
        <FullScreen handle={handle}>
          <CardMedia
            alt="GeeksforGeeks"
            component="iframe"
            width="100%"
            height="100%"
            title={curObject.otitle}
            src={`${curObject.ourl}#toolbar=0&navpanes=0`}
            type="application/pdf"
            onLoad={onDocumentLoadSuccess()}
            frameBorder={0}
          />
        </FullScreen>
      </div>
    );
  }

  function pptView() {
    const googleDocsViewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(
      curObject.ourl
    )}&embedded=true`;
    return (
      <div className={classes.pdfContainer}>
        <iframe
          src={googleDocsViewerUrl}
          title="pdf"
          height="500"
          width="100%"
        ></iframe>
      </div>
    );
  }
  function WebView() {
    return (
      <FullScreen handle={handle}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            fontFamily: `myfontregular`,
          }}
        >
          <CardMedia
            alt="GeeksforGeeks"
            component="iframe"
            width="100%"
            height="100%"
            title={curObject.otitle}
            src={curObject.ourl}
            onLoad={onDocumentLoadSuccess()}
            className={classes.documentSection}
          />
        </div>
      </FullScreen>
    );
  }
  function findHighest(array) {
    if (array.length === 0) {
      return null; // Return null for an empty array
    }
    let highest = Number(array[0]); // Convert the first element to a number
    for (let i = 1; i < array.length; i++) {
      const num = Number(array[i]); // Convert each element to a number
      if (!isNaN(num) && num > highest) {
        highest = num;
      }
    }
    return highest;
  }
  function shuffleArray(arr) {
    let shuffledArr = [...arr];
    for (let i = shuffledArr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArr[i], shuffledArr[j]] = [shuffledArr[j], shuffledArr[i]];
    }
    return shuffledArr;
  }
  async function getQuiz(obj) {
    setIsLoading(true);
    const jsonString = JSON.stringify({
      obj_id: obj.oid,
      oid: config.aws_org_id,
      schema: config.schema,
      ur_id: userDetails.uData?.ur_id,
      key: courseDetails.tid,
    });
    const base64EncodedString = btoa(jsonString);
    const bodyParam = {
      body: {
        json: base64EncodedString,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken,
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getQuiz",
        bodyParam
      );
      if (obj.qtype !== 4) {
        const { qitems, cutoff, currentQuestion = null } = response;
        setCutOff(cutoff);
        setQuestion(qitems);
        let questTypeFourSHF = shuffleArray(qitems);
        setQuestion(questTypeFourSHF);
        const allHaveYouAnswered = qitems.every((obj) => "youAnswered" in obj);
        // If all objects have 'youAnswered', create a new array without the key
        if (allHaveYouAnswered) {
          const newData = qitems.map((obj) => {
            obj.iopts.forEach((item) => {
              delete item.youAnswered;
            });
            const { youAnswered, answeredOption, ...rest } = obj;
            return rest;
          });
          setQuestion(newData);
          setCurrentQuestion(0);
          setTimer(curObject?.oduration);
        }
        if (currentQuestion && curObject.timer) {
          setCurrentQuestion(currentQuestion);
        }
        const storedTimer =
          config.aws_org_id !== "EXCELEARN"
            ? curObject?.rtime || curObject?.oduration
            : curObject?.oduration;
        if (storedTimer) {
          setTimer(storedTimer);
        }
      }
      if (obj.qtype === 4) {
        const { cutoff, qitems, totalmarks } = response;
        let questTypeFour = shuffleArray(qitems);
        setQuestion(questTypeFour);
        setMainSetOfQuestion(response);
        setCutOff(cutoff);
        setTotalMarks(totalmarks);
      }
      setIsLoading(false);
      setIsRunning(true);
      setStartQuiz(true);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }
  const scoreAchieved = (scoreD) => {
    if ((scoreD / curObject.quiz_length) * 100 >= parseInt(curObject.cutoff))
      return true;
    return false;
  };
  function AnsAch() {
    if (curObject.score && curObject.score.length > 0) {
      if (scoreAchieved(parseInt(curObject.score[curObject.score.length - 1])))
        return true;
      else return false;
    } else {
      return false;
    }
  }
  function tempView() {
    return (
      <React.Fragment>
        {courseDetails.nuggets[curNugIndex]?.objects[curObjIndex].qtype ===
          2 && (
          <React.Fragment>
            <FormativeQuiz
              question={question}
              timer={timer}
              setTimer={setTimer}
              setQuestion={setQuestion}
              setCurrentQuestion={setCurrentQuestion}
              currentQuestion={currentQuestion}
              loading={isLoading}
              courseDetails={courseDetails}
              cutOff={cutOff}
              setMarkComplete={setMarkComplete}
              getQuiz={getQuiz}
              setLoading={setIsLoading}
              startQuiz={startQuiz}
              setStartQuiz={setStartQuiz}
              curObject={curObject}
              showScore={showScore}
              setShowScore={setShowScore}
              setProgress={setProgress}
              score={perScore}
              setScore={setPerScore}
              nextObject={nextobject}
              setTimerPalyPause={setTimerPalyPause}
              syncUserProgress={syncUserProgress}
              syncUserProgresswhilequiz={syncUserProgresswhilequiz}
              startTime={startTime}
              curNugget={curNugget}
              curObjIndex={curObjIndex}
              totalProgress={totalProgress}
              setTotalProgress={setTotalProgress}
              setRemaining={setRemaining}
              getTopicDetails={getTopicDetails}
              setCourseDetails={setCourseDetails}
              curNugIndex={curNugIndex}
              showAnswers={showAnswers}
              setShowAnswers={setShowAnswers}
              isRunning={isRunning}
              setIsRunning={setIsRunning}
              nextcourse={nextcourse}
              open={open}
              setOpen={setOpen}
              handleModalClose={handleModalClose}
            />
            {!loading &&
              !startQuiz &&
              !showAnswers &&
              !AnsAch() &&
              !(
                curObject?.score !== undefined &&
                curObject?.score?.length >= curObject?.attempt
              ) && (
                <div
                  className="formative_start_button_container"
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {curObject?.instructions?.instruction && (
                    <div>
                      <h2
                        style={{
                          fontSize: "3rem",
                          marginTop: "8rem",
                          marginBottom: "2rem",
                          textAlign: "center",
                        }}
                      >
                        {curObject.otitle}
                      </h2>
                      <h2 style={{ marginBottom: "2rem", textAlign: "center" }}>
                        Instructions:
                      </h2>
                      <div>
                        {curObject?.instructions?.instruction?.map(
                          (item, index) => (
                            <div
                              key={index}
                              style={{
                                justifyContent: "center",
                                listStyleType: "none",
                                paddingLeft: 0,
                                marginBottom: "2px",
                              }}
                            >
                              {index + 1}. {item}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )}
                  <button
                    className="button-3"
                    onClick={() => {
                      getQuiz(curObject);
                      setIsRunning(true);
                    }}
                  >
                    {isLoading ? (
                      <CircularProgress
                        style={{
                          fontSize: "2.5rem",
                          height: "2.5rem",
                          width: "2.5rem",
                        }}
                      />
                    ) : (
                      `${userDetails?.languagejson?.Course_View?.Start_Quiz ||
                        "Start Quiz"}`
                    )}
                  </button>
                </div>
              )}
            {!AnsAch() &&
              curObject?.score?.length >= curObject?.attempt &&
              !showAnswers &&
              !showScore && (
                <div style={{ padding: "30px 0px 30px 0px" }}>
                  <div
                    className="formative_start_button_container"
                    style={{ marginBottom: "3rem" }}
                  >
                    Your Attempts has been completed, please click on Show
                    Answers to view your last response
                    <StyledScoreContainer
                      primary={scoreAchieved(curObject?.score?.at(-1))}
                      style={{ marginTop: "4rem" }}
                    >
                      <div className="show-score-header">
                        <h1 style={{ marginTop: "2rem" }}>
                          {curObject?.score?.at(-1)}/{curObject?.quiz_length}
                        </h1>
                        <h4
                          style={{
                            marginBottom: "1rem",
                            marginTop: "1rem",
                            color: "black",
                          }}
                        >
                          Your Score
                        </h4>
                        <button
                          className="button-3"
                          style={{
                            backgroundColor:
                              !scoreAchieved(curObject?.score?.at(-1)) &&
                              !AnsAch()
                                ? "#e38888"
                                : "#1d8417",
                          }}
                        >
                          {!scoreAchieved(curObject?.score?.at(-1)) && !AnsAch()
                            ? "Fail"
                            : "Pass"}
                        </button>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "3rem",
                        }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <ul
                            style={{
                              listStyleType: "none",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            <li style={{ color: "#007bff" }}>
                              &#8226;{" "}
                              {Math.round(
                                (curObject?.score?.at(-1) /
                                  curObject?.quiz_length) *
                                  100
                              )}
                              %
                            </li>
                          </ul>
                          <div>Total Percentage</div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "8rem",
                          }}
                        >
                          <ul
                            style={{
                              listStyleType: "none",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            <li style={{ color: "#000000" }}>
                              &#8226; {curObject?.quiz_length}
                            </li>
                          </ul>
                          <div>Total Questions</div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "3rem",
                        }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <ul
                            style={{
                              listStyleType: "none",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            <li style={{ color: "#00ff00" }}>
                              &#8226; {curObject?.score?.at(-1)}
                            </li>
                          </ul>
                          <div>Correct Answers</div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "8rem",
                          }}
                        >
                          <ul
                            style={{
                              listStyleType: "none",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            <li style={{ color: "#ff0000" }}>
                              &#8226;{" "}
                              {curObject?.quiz_length -
                                curObject?.score?.at(-1)}
                            </li>
                          </ul>
                          <div>Wrong Answers</div>
                        </div>
                      </div>
                    </StyledScoreContainer>
                    <button
                      className="button-3"
                      onClick={() => {
                        setShowAnswers(true);
                        setStartQuiz(false);
                      }}
                      style={{ marginTop: "8rem" }}
                    >
                      {`${userDetails?.languagejson?.Course_View
                        ?.Show_Answers || "Show Answers"}`}
                    </button>
                    <div style={{ margin: "2rem 0" }}>
                      <button className="button-3" onClick={() => nextobject()}>
                        {courseDetails.nuggets.at(-1).objects.at(-1).oid ===
                        courseDetails.nuggets[curNugIndex].objects[curObjIndex]
                          .oid
                          ? "Click here to exit Course"
                          : "Next"}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            {AnsAch() && !showAnswers && !showScore && (
              <div>
                <div>
                  <div className="formative_start_button_container">
                    {scoreAchieved(curObject?.score?.at(-1)) && (
                      <div
                        style={{
                          marginTop: "4rem",
                          marginBottom: "4rem",
                          display: "flex",
                          justifyContent: "center",
                          gap: "20px",
                          alignItems: "center",
                        }}
                      >
                        <Confetti style={{ height: "50", width: "46" }} />{" "}
                        <h1 className={classes.mainHeading}>
                          Congratulations!!!
                        </h1>
                        <ReverseConfetti
                          style={{ height: "55", width: "55" }}
                        />
                      </div>
                    )}
                    <StyledScoreContainer
                      primary={
                        scoreAchieved(curObject?.score?.at(-1)) &&
                        curObject?.score?.length <= curObject?.attempt
                      }
                    >
                      <div className="show-score-header">
                        <h1 style={{ marginTop: "2rem" }}>
                          {curObject?.score?.at(-1)}/{curObject?.quiz_length}
                        </h1>
                        <h4
                          style={{
                            marginBottom: "1rem",
                            marginTop: "1rem",
                            color: "black",
                          }}
                        >
                          Your Score
                        </h4>
                        {config.passBtn && (
                          <button
                            className="button-3"
                            style={{
                              backgroundColor:
                                !scoreAchieved(curObject?.score?.at(-1)) &&
                                !AnsAch()
                                  ? "#e38888"
                                  : "#1d8417",
                            }}
                          >
                            {!scoreAchieved(curObject?.score?.at(-1)) &&
                            !AnsAch()
                              ? "Fail"
                              : "Pass"}
                          </button>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "3rem",
                        }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <ul
                            style={{
                              listStyleType: "none",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            <li style={{ color: "#007bff" }}>
                              &#8226;{" "}
                              {Math.round(
                                (curObject?.score?.at(-1) /
                                  curObject?.quiz_length) *
                                  100
                              )}
                              %
                            </li>
                          </ul>
                          <div>Total Percentage</div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "5rem",
                          }}
                        >
                          <ul
                            style={{
                              listStyleType: "none",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            <li style={{ color: "#000000" }}>
                              &#8226; {curObject?.quiz_length}
                            </li>
                          </ul>
                          <div>Total Questions</div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "3rem",
                          marginBottom: "2rem",
                        }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <ul
                            style={{
                              listStyleType: "none",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            <li style={{ color: "#00ff00" }}>
                              &#8226; {curObject?.score?.at(-1)}
                            </li>
                          </ul>
                          <div>Correct Answers</div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "8rem",
                          }}
                        >
                          <ul
                            style={{
                              listStyleType: "none",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            <li style={{ color: "#ff0000" }}>
                              &#8226;{" "}
                              {curObject?.quiz_length -
                                curObject?.score?.at(-1)}
                            </li>
                          </ul>
                          <div>Wrong Answers</div>
                        </div>
                      </div>
                    </StyledScoreContainer>
                    <div className={classes.allButtons}>
                      <button
                        className="button-3"
                        onClick={() => {
                          setShowAnswers(true);
                          setStartQuiz(false);
                        }}
                      >
                        {`${userDetails?.languagejson?.Course_View
                          ?.Show_Answers || "Show Answers"}`}
                      </button>
                      <button className="button-3" onClick={() => nextobject()}>
                        {courseDetails.nuggets.at(-1).objects.at(-1).oid ===
                        courseDetails.nuggets[curNugIndex].objects[curObjIndex]
                          .oid
                          ? "Click here to exit Course"
                          : "Next"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </React.Fragment>
        )}
        {curObject.qtype === 4 && (
          <React.Fragment>
            <FormativeQuizQuestionBank
              question={question}
              setQuestion={setQuestion}
              loading={isLoading}
              courseDetails={courseDetails}
              cutOff={cutOff}
              setMarkComplete={setMarkComplete}
              getQuiz={getQuiz}
              setLoading={setIsLoading}
              startQuiz={startQuiz}
              setStartQuiz={setStartQuiz}
              curObject={curObject}
              showScore={showScore}
              setShowScore={setShowScore}
              setProgress={setProgress}
              score={perScore}
              setScore={setPerScore}
              nextObject={nextobject}
              setTimerPalyPause={setTimerPalyPause}
              syncUserProgress={syncUserProgress}
              syncUserProgresswhilequiz={syncUserProgresswhilequiz}
              startTime={startTime}
              curNugget={curNugget}
              curObjIndex={curObjIndex}
              totalProgress={totalProgress}
              setTotalProgress={setTotalProgress}
              setRemaining={setRemaining}
              getTopicDetails={getTopicDetails}
              setCourseDetails={setCourseDetails}
              curNugIndex={curNugIndex}
              showAnswers={showAnswers}
              setShowAnswers={setShowAnswers}
              mainSetOfQuestions={mainSetOfQuestions}
              totalMarks={totalMarks}
            />
            {!startQuiz &&
              !showAnswers &&
              courseDetails.nuggets[curNugIndex]?.objects[curObjIndex].op !==
                2 && (
                <div className="formative_start_button_container">
                  {config.aws_org_id === "NSECORP" && (
                    <div
                      dangerouslySetInnerHTML={instructions?.assessment}
                    ></div>
                  )}
                  {curObject?.instructions?.instruction && (
                    <div
                      style={{
                        margin: "auto",
                        width: "60%",
                        alignContent: "center",
                      }}
                    >
                      <h2
                        style={{
                          fontSize: "3rem",
                          marginTop: "8rem",
                          marginBottom: "2rem",
                          textAlign: "center",
                        }}
                      >
                        {curObject.otitle}
                      </h2>
                      <h2 style={{ marginBottom: "2rem", textAlign: "center" }}>
                        Instructions:
                      </h2>
                      <div>
                        {curObject?.instructions?.instruction?.map(
                          (item, index) => (
                            <div
                              key={index}
                              style={{
                                justifyContent: "center",
                                listStyleType: "none",
                                paddingLeft: 0,
                                marginBottom: "2px",
                              }}
                            >
                              {index + 1}. {item}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      className="button-3"
                      onClick={() => {
                        getQuiz(curObject);
                      }}
                    >
                      {isLoading ? (
                        <CircularProgress
                          style={{
                            fontSize: "2.5rem",
                            height: "2.5rem",
                            width: "2.5rem",
                          }}
                        />
                      ) : (
                        `${userDetails?.languagejson?.Course_View?.Start_Quiz ||
                          "Start Quiz"}`
                      )}
                    </button>
                  </div>
                </div>
              )}
            {!startQuiz &&
              courseDetails.nuggets[curNugIndex]?.objects[curObjIndex].op ===
                2 &&
              !showAnswers &&
              !showScore &&
              courseDetails.nuggets[curNugIndex]?.objects[curObjIndex]
                ?.quiz_json === null && (
                <div className="formative_start_button_container">
                  <button
                    className="button-3"
                    onClick={() => {
                      getQuiz(curObject);
                    }}
                  >
                    {isLoading ? (
                      <CircularProgress
                        style={{
                          fontSize: "2.5rem",
                          height: "2.5rem",
                          width: "2.5rem",
                        }}
                      />
                    ) : (
                      `${userDetails?.languagejson?.Course_View?.Start_Quiz ||
                        "Start Quiz"}`
                    )}
                  </button>
                </div>
              )}
            {!startQuiz &&
              courseDetails.nuggets[curNugIndex]?.objects[curObjIndex].op ===
                2 &&
              !showAnswers &&
              !showScore &&
              courseDetails.nuggets[curNugIndex]?.objects[curObjIndex]
                ?.quiz_json !== null && (
                <>
                  <StyledScoreContainer primary={true}>
                    <h1 className="show-score-header">
                      <span>
                        {findHighest(
                          courseDetails.nuggets[curNugIndex]?.objects[
                            curObjIndex
                          ].score
                        )}
                        /
                        {
                          courseDetails.nuggets[curNugIndex]?.objects[
                            curObjIndex
                          ]?.quiz_json?.json?.totalmarks
                        }
                      </span>
                    </h1>
                    <button
                      className="button-3"
                      style={{ marginTop: "10px" }}
                      onClick={() => {
                        setShowAnswers(true);
                      }}
                    >
                      {`${userDetails?.languagejson?.Course_View
                        ?.Show_Answers || "Show Answers"}`}
                    </button>
                    <div
                      style={{ fontWeight: "600", padding: "20px" }}
                    >{`${userDetails?.languagejson?.Course_View
                      ?.Congratulations_on_course ||
                      "Congratulation on Successfully completing the"} ${
                      courseDetails.ttitle
                    } `}</div>
                  </StyledScoreContainer>
                </>
              )}
          </React.Fragment>
        )}
        {curObject.qtype === 0 && (
          <React.Fragment>
            <FormativeQuizWithFeedbackQuiz
              question={question}
              setQuestion={setQuestion}
              loading={isLoading}
              courseDetails={courseDetails}
              cutOff={cutOff}
              setMarkComplete={setMarkComplete}
              getQuiz={getQuiz}
              setLoading={setIsLoading}
              startQuiz={startQuiz}
              setStartQuiz={setStartQuiz}
              curObject={curObject}
              showScore={showScore}
              setShowScore={setShowScore}
              setProgress={setProgress}
              score={perScore}
              setScore={setPerScore}
              nextObject={nextobject}
              setTimerPalyPause={setTimerPalyPause}
              syncUserProgress={syncUserProgress}
              syncUserProgresswhilequiz={syncUserProgresswhilequiz}
              startTime={startTime}
            />
            {!startQuiz && (
              <div className="formative_start_button_container">
                {config.aws_org_id === "NSECORP" && (
                  <div dangerouslySetInnerHTML={instructions?.mcq}></div>
                )}
                {curObject?.instructions?.instruction && (
                  <div
                    style={{
                      margin: "auto",
                      width: "60%",
                      alignContent: "center",
                    }}
                  >
                    <h2
                      style={{
                        fontSize: "3rem",
                        marginTop: "8rem",
                        marginBottom: "2rem",
                        textAlign: "center",
                      }}
                    >
                      {curObject.otitle}
                    </h2>
                    <h2 style={{ marginBottom: "2rem", textAlign: "center" }}>
                      Instructions:
                    </h2>
                    <div>
                      {curObject?.instructions?.instruction?.map(
                        (item, index) => (
                          <div
                            key={index}
                            style={{
                              listStyleType: "none",
                              paddingLeft: 0,
                              marginTop: "8px",
                              marginBottom: "2px",
                            }}
                          >
                            {index + 1}. {item}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <button
                    className="button-3"
                    onClick={() => {
                      getQuiz(curObject);
                    }}
                  >
                    {isLoading ? (
                      <CircularProgress
                        style={{
                          fontSize: "2.5rem",
                          height: "2.5rem",
                          width: "2.5rem",
                        }}
                      />
                    ) : (
                      `${userDetails?.languagejson?.Course_View?.Start_Quiz ||
                        "Start Quiz"}`
                    )}
                  </button>
                </div>
              </div>
            )}
          </React.Fragment>
        )}
        {curObject.qtype === 1 && (
          <React.Fragment>
            <SummativeQuiz
              question={question}
              setQuestion={setQuestion}
              loading={isLoading}
              setLoading={setIsLoading}
              startQuiz={startQuiz}
              setStartQuiz={setStartQuiz}
              getQuiz={getQuiz}
              curObject={curObject}
              setProgress={setProgress}
              setMarkComplete={setMarkComplete}
              syncUserProgresswhilequiz={syncUserProgresswhilequiz}
              courseDetails={courseDetails}
              score={perScore}
              setPerScore={setPerScore}
              setShowScore={setShowScore}
              nextObject={nextobject}
              showScore={showScore}
              curNugIndex={curNugIndex}
              curNugget={curNugget}
              curObjIndex={curObjIndex}
              nextCourseref={nextCourseref}
              setTimerPalyPause={setTimerPalyPause}
              totalScore={totalScore}
              setTotalScore={setTotalScore}
              nextcourse={nextcourse}
              getUserCertificate={getUserCertificate}
              getTopicDetails={getTopicDetails}
              lastobject={lastobject}
            />
            {!startQuiz && !showScore && (
              <div className="formative_start_button_container-2">
                {curObject?.instructions?.instruction && (
                  <div
                    style={{
                      margin: "auto",
                      width: "60%",
                      alignContent: "center",
                    }}
                  >
                    <h2
                      style={{
                        fontSize: "3rem",
                        marginTop: "8rem",
                        marginBottom: "2rem",
                        textAlign: "center",
                      }}
                    >
                      {curObject.otitle}
                    </h2>
                    <h2 style={{ marginBottom: "2rem", textAlign: "center" }}>
                      Instructions:
                    </h2>
                    <div>
                      {curObject?.instructions?.instruction?.map(
                        (item, index) => (
                          <div
                            key={index}
                            style={{
                              justifyContent: "center",
                              listStyleType: "none",
                              paddingLeft: 0,
                              marginBottom: "2px",
                            }}
                          >
                            {index + 1}. {item}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}
                <div>
                  <h3
                    variant="h3"
                    style={{
                      textTransform: "capitalize",
                      fontWeight: "600",
                      marginBottom: "5rem",
                    }}
                  >
                    {courseDetails.pname}
                  </h3>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <div className={classes.scoreperQuestionStyle}>
                      <div
                        style={{
                          padding: "2rem",
                          borderRight: "1px solid #777",
                          flexGrow: "1",
                        }}
                      >
                        <p>
                          <HelpOutlineIcon style={{ fontSize: 30 }} />{" "}
                        </p>
                        <p>{`${userDetails?.languagejson?.Course_View
                          ?.Questions || "Questions"}`}</p>
                        <p>
                          {curObject.totalq
                            ? curObject.totalq
                            : curObject.oduration / 60}
                        </p>
                      </div>
                      <div style={{ padding: "2rem", flexGrow: "1" }}>
                        <p>
                          <TimerIcon style={{ fontSize: 30 }} />
                        </p>
                        <p>{`${userDetails?.languagejson?.Course_View
                          ?.Time_allocated || "Time allocated"}`}</p>
                        <p>{`${Math.floor(
                          curObject.oduration / 60
                        )}m : ${Math.floor(curObject.oduration % 60)}s`}</p>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                      justifyContent: "center",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                    <h3>
                      {`${userDetails?.languagejson?.Course_View
                        ?.Score_per_question || "Score per question"}`}
                      : 1
                    </h3>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <button
                    className="button-3"
                    onClick={() => {
                      getQuiz(curObject);
                    }}
                  >
                    {loading ? (
                      <CircularProgress
                        style={{
                          fontSize: "2.5rem",
                          height: "2.5rem",
                          width: "2.5rem",
                        }}
                      />
                    ) : (
                      `${userDetails?.languagejson?.Course_View?.Start_Quiz ||
                        "Start Quiz"}`
                    )}
                  </button>
                </div>
              </div>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
  function handlefeedbacktextChange(e, qind) {
    let temp_state = [...question];
    let temp_element = { ...temp_state[qind] };
    temp_element.opindex = e.target.value;
    temp_state[qind] = temp_element;
    setQuestion(temp_state);
    questionsRef.current = temp_state;
  }
  const handlefeedbackChange = (event) => {
    let temp_state = [...question];
    // 2. Make a shallow copy of the element you want to mutate
    let temp_element = { ...temp_state[event.target.name] };
    // 3. Update the property you're interested in
    temp_element.value = event.target.value;
    const index = temp_element.iopts.findIndex(
      (item) => item.content === event.target.value
    );
    if (index !== -1) {
      temp_element.opindex = index;
    }
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    temp_state[event.target.name] = temp_element;
    // 5. Set the state to our new copy
    setQuestion(temp_state);
    questionsRef.current = temp_state;
  };
  function challenge(len) {
    if (len === 2) {
      return "challenge2";
    } else if (len === 3) {
      return "challenge3";
    } else if (len === 4) {
      return "challenge4";
    } else if (len === 5) {
      return "challenge5";
    }
  }
  function feedbackView() {
    return (
      <div
        ref={scroll}
        style={
          loading || question.length == 0
            ? {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }
            : {
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
                overflowY: "auto",
                overflowX: "hidden",
                position: "relative",
                height: "100%",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "100%",
              }
        }
      >
        {startQuiz ? (
          <>
            <>
              {loading || question.length == 0 ? (
                <CircularProgress color="default" size={30} />
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1.5rem",
                      justifyContent: "center",
                      marginBottom: "3rem",
                      width: "100%",
                    }}
                  >
                    {question.map((question, qind) => (
                      <div
                        style={question.qtype == 3 ? { display: "flex" } : null}
                      >
                        <div style={{ height: "fit-content" }}>
                          <h4
                            style={{
                              marginBottom: "1rem",
                              fontWeight: "600",
                              color: "#555555",
                              display: "inline-block",
                              height:
                                question.istem.length > 100 ? "110px" : "",
                            }}
                          >
                            {qind + 1}. {question.istem}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </h4>
                        </div>
                        <RadioGroup
                          aria-label="gender"
                          name={qind}
                          value={question.value}
                          onChange={handlefeedbackChange}
                        >
                          <div className={classes.quizroot}>
                            {question.qtype == 0 ? (
                              <div style={{ position: "relative" }}>
                                <div
                                  className={challenge(question.iopts.length)}
                                  style={{ zIndex: "2" }}
                                >
                                  {question.iopts.map((option, opind) => (
                                    <div>
                                      <FormControlLabel
                                        value={option.content}
                                        control={<GreenRadio />}
                                        label={option.content}
                                        key={opind}
                                        className={`${classes.label} ${classes.label2} ${classesse.h}`}
                                      />
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ) : (
                              <>
                                {question.qtype == 2 ? (
                                  <>
                                    <TextareaAutosize
                                      style={{
                                        width: "97%",
                                        height: "80px",
                                        backgroundColor: "transparent",
                                        resize: "none",
                                        padding: ".8rem 1.5rem",
                                        marginLeft: "2rem",
                                        outline: "none",
                                        border: "1px solid #1C6D9C",
                                        borderRadius: ".5rem",
                                      }}
                                      rowsMax={2}
                                      aria-label="maximum height"
                                      placeholder={question.istem}
                                      value={question.response}
                                      onChange={(e) =>
                                        handlefeedbacktextChange(e, qind)
                                      }
                                      required
                                    />
                                  </>
                                ) : (
                                  <>
                                    {question.qtype == 3 ? (
                                      <select
                                        name="job"
                                        value={question.response}
                                        onChange={(event) => {
                                          let temp_state = [...question];
                                          let temp_element = {
                                            ...temp_state[qind],
                                          };
                                          temp_element.opindex =
                                            event.target.value;
                                          temp_state[qind] = temp_element;
                                          setQuestion(temp_state);
                                          handlefeedbacktextChange(event, qind);
                                        }}
                                        style={{
                                          marginLeft: "2rem",
                                          height: "25px",
                                          width: "200px",
                                        }}
                                        required
                                      >
                                        <option key="select" value="">
                                          select option
                                        </option>
                                        {question.iopts.map((e, key) => {
                                          return (
                                            <option key={key} value={e}>
                                              {e}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    ) : (
                                      <Grid container spacing={5}>
                                        {question.iopts.map((option, opind) => (
                                          <Grid item xs={5}>
                                            <FormControlLabel
                                              value={option.content}
                                              control={
                                                <Radio
                                                  style={{
                                                    color: "black",
                                                  }}
                                                  required
                                                />
                                              }
                                              label={option.content}
                                              key={opind}
                                              className={classes.labelSurvey}
                                            />
                                          </Grid>
                                        ))}
                                      </Grid>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </RadioGroup>
                      </div>
                    ))}
                    {surveyError !== "" ? (
                      <h3
                        style={{
                          color: "red",
                          fontWeight: "600",
                          fontSize: "1.6rem",
                          textAlign: "left",
                          justifySelf: "flex-start",
                          width: "100%",
                        }}
                      >
                        {surveyError}
                      </h3>
                    ) : null}
                    <div className="flex-center">
                      <StyledButtonQuiz
                        onClick={() => {
                          setSurveyError("");
                          SurveyValidation();
                        }}
                      >
                        Next
                      </StyledButtonQuiz>
                    </div>
                  </div>
                </>
              )}
            </>
          </>
        ) : (
          <>
            <div>
              {loading ? (
                <CircularProgress color="default" size={30} />
              ) : (
                <div className="centeritem" style={{ height: "63vh" }}>
                  {curObject.op == 2 ? (
                    <>
                      <Typography variant="h2">Survey Completed</Typography>
                    </>
                  ) : (
                    <StyledButtonQuiz
                      onClick={() => {
                        questionsRef.current = [];
                        setStartQuiz(false);
                        retake.current = false;
                        getQuiz(curObject);
                        setShowScore(false);
                        setLoading(true);
                        setSurveyError("");
                      }}
                    >
                      Take survey
                    </StyledButtonQuiz>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    );
  }
  function handlemarkcomplete() {
    syncUserProgress();
  }
  async function nextobject() {
    setQuestion([]);
    questionsRef.current = [];
    setShowScore(false);
    setStartQuiz(false);
    setLoading(true);
    let coindex = curObjIndex;
    coindex++;
    setCurObjIndex(coindex);
    if (
      (curNugget.objects[coindex]?.otype !== "scorm" &&
        curNugget.objects[coindex]?.op == 1) ||
      curNugget.objects[coindex]?.op === 0
    ) {
      setMarkComplete(true);
    } else {
      setMarkComplete(false);
    }
    if (coindex == curNugget?.objects.length) {
      let curnugind = curNugIndex;
      setCurObjIndex(0);
      curnugind++;
      setCurNugIndex(curnugind);
      if (courseDetails.nuggets[curnugind] !== undefined) {
        setCurNugget(courseDetails.nuggets[curnugind]);
        setCurObject(courseDetails.nuggets[curnugind].objects[0]);
        if (
          courseDetails.nuggets[curnugind].objects[0].otype == "quiz" &&
          courseDetails.nuggets[curnugind].objects[0].op == 2 &&
          courseDetails.nuggets[curnugind].objects[0].qtype == 1
        ) {
          setLoading(true);
        } else {
          setLoading(false);
          setIsLoading(false);
          getTopicDetails();
        }
        if (
          courseDetails.ctype == "enforced" &&
          courseDetails.nuggets[curnugind].objects[0].op == 1
        ) {
          setProgress(
            courseDetails.nuggets[curnugind]?.objects[0]?.rtime ??
              courseDetails.nuggets[curnugind]?.objects[0]?.oduration
          );
          if (
            courseDetails.nuggets[curnugind].objects[0].otype != "video" &&
            courseDetails.nuggets[curnugind].objects[0].otype != "quiz" &&
            courseDetails.nuggets[curnugind].objects[0].otype != "feedback"
          ) {
            setTimerPalyPause("play");
          }
        }
      } else if (curnugind == courseDetails.nuggets.length) {
        if (nextCourseref.current == true) {
          if (
            courseDetails.certification == "true" ||
            courseDetails.certification === true
          ) {
            setOpen(true);
            getUserCertificate(userDetails);
          } else {
            await nextcourse();
          }
          nextCourseref.current = false;
        } else {
          setOpen(false);
        }
        if (
          courseDetails.freenavigation == true ||
          courseDetails.freenavigation == "true"
        ) {
          await nextcourse();
        } else {
          setOpen(false);
        }
        if (
          courseDetails.certification == true ||
          courseDetails.certification == "true"
        ) {
          getUserCertificate(userDetails);
        } else {
          setOpen(false);
        }
      } else {
        setOpen(false);
        setLoading(false);
        setIsLoading(false);
        getTopicDetails();
      }
    } else {
      setCurObject(curNugget.objects[coindex]);
      if (
        curNugget.objects[coindex].otype == "feedback" &&
        curNugget.objects[coindex].op == 1
      ) {
        setStartQuiz(true);
      }
      if (
        curNugget.objects[coindex].otype == "scorm" &&
        curNugget.objects[coindex].op == 1
      ) {
        scormpPause.current = false;
        retake.current = false;
      } else {
        scormpPause.current = true;
      }
      if (
        courseDetails.ctype == "enforced" &&
        curNugget.objects[coindex].op == 1
      ) {
        setProgress(
          curNugget?.objects[coindex]?.rtime ??
            curNugget?.objects[coindex]?.oduration
        );
        if (
          curNugget.objects[coindex].otype != "video" &&
          curNugget.objects[coindex].otype != "quiz" &&
          curNugget.objects[coindex].otype != "feedback"
        ) {
          setTimerPalyPause("play");
        }
      }
      if (
        curNugget.objects[coindex].otype == "quiz" &&
        curNugget.objects[coindex].op == 2 &&
        curNugget.objects[coindex].qtype == 1
      ) {
        setLoading(true);
        getQuizScore(curNugget.objects[coindex]);
      } else {
        setLoading(false);
        setIsLoading(false);
      }
      setIsLoading(false);
    }
    setShowScore(false);
    window.nextbtn = undefined;
  }
  async function getQuizScore(objects) {
    try {
      setLoading(true);
      const requestBody = {
        oid: config.aws_org_id,
        tenant: userDetails.locale,
        eid: userDetails.username,
        emailid: userDetails.email,
        obj_id: objects.oid,
        ur_id: userDetails.uData.ur_id,
        tid: courseDetails.tid,
        schema: config.schema,
      };
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = `/getQuizScore?json=${encodeURIComponent(
        base64EncodedString
      )}`;
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: userDetails?.accessToken,
          },
        }
      );
      setShowScore(true);
      setLoading(false);
      setStartQuiz(false);
      if (response && response.length > 0) {
        setPerScore(response[response?.length - 1]);
        setTotalScore(response[response?.length - 1]);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }
  async function nextcourse() {
    let nxtvar = "",
      btnmsg = "";
    if (
      courseDetails.nextcourse == true ||
      courseDetails.nextcourse == "true"
    ) {
      nxtvar = `${userDetails?.languagejson?.Course_Completion?.Up_next ||
        "Up next"}, ${courseDetails.nextcoursedata.ttitle}<br>`;
      btnmsg = `${userDetails?.languagejson?.Course_Completion
        ?.Please_click_here || "Click here to get started"}`;
    } else {
      nxtvar =
        config.aws_org_id === "NSECORP"
          ? `${userDetails?.languagejson?.Course_View?.To_go_home_page ||
              "To go home page,"}`
          : `${userDetails?.languagejson?.Course_Completion
              ?.To_view_some_of_our_other_courses ||
              `To view some of our other courses,<br> `}`;
      btnmsg =
        config.aws_org_id === "EXCELEARN"
          ? "Go To DashBoard"
          : `${userDetails?.languagejson?.Course_Completion
              ?.Please_click_here || "Click here to get started"}`;
    }
    if (config.aws_org_id === "EXCELEARN" && (await passFail(courseDetails))) {
      setShouldShowCertificate(false);
      showAlert();
    } else {
      setShouldShowCertificate(true);
      Swal.fire({
        title: `${userDetails?.languagejson?.Course_View?.Congratulations ||
          "Congratulations!"}`,
        width: 400,
        html: `
        <h3 style="font-size:1.5625rem">
          ${userDetails?.languagejson?.Course_View?.You_completed_the_course ||
            "You completed the course"} - ${courseDetails?.ttitle}
        </h3>
        <br>
        ${
          config.aws_org_id === "EXCELEARN"
            ? `
          <h3 style="font-size:1.5625rem">
            ${nxtvar} <a href='#/viewAllCourses'  id = "navigateViewAllCourse">click here</a>
          </h3>`
            : `<h3 style="font-size:1.5625rem">
            ${nxtvar}
          </h3>`
        }
       `,
        didRender: () => {
          const clickHereElement = document.getElementById(
            "navigateViewAllCourse"
          );
          if (clickHereElement) {
            clickHereElement.onclick = function() {
              Swal.close();
            };
          }
        },
        showCloseButton: true,
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: `<h3 style="color:#0a3055;"> ${btnmsg}</h3>`,
        allowOutsideClick: false,
        customClass: "nextcourseswal",
      }).then((result) => {
        if (result.isConfirmed) {
          if (
            courseDetails.nextcourse == true ||
            courseDetails.nextcourse == "true"
          ) {
            let breadcrumb = { ...userDetails };
            breadcrumb.location = {
              from: breadcrumb.location.from,
              courseName: courseDetails?.nextcoursedata?.ttitle,
              prevLoc: breadcrumb.location.prevLoc,
              programName: breadcrumb.location.programName,
              topicName: breadcrumb.location.topicName,
              topicId: breadcrumb.location.topicId,
              viewAll: breadcrumb.location.viewAll,
              programId: breadcrumb.location.programId,
              cid: breadcrumb.location.cid,
            };
            dispatch(awsSignIn(breadcrumb));
            history.push(`/course?id=${courseDetails.nextcoursedata.tid}`);
            window.location.reload();
          } else {
            if (
              config.aws_org_id === "NSECORP" ||
              config.aws_org_id === "EXCELEARN"
            ) {
              history.push("/home");
            } else {
              history.push("/viewAllCourses");
            }
          }
        }
        if (result.isDenied) {
          alert("User clicked deny");
        }
      });
    }
  }
  async function syncUserCertificate(otype) {
    try {
      if (otype !== "quiz") {
        setOpen(true);
        setCertificate(true);
        getUserCertificate(userDetails);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }
  function capitalize(word) {
    let capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    return capitalized;
  }
  async function getUserCertificate(userDetails) {
    setOpen(true);
    setCertificate(true);
    if (config.aws_org_id === "EXCELEARN") {
      if (await passFail(courseDetails)) {
        setShouldShowCertificate(false);
        showAlert();
      } else {
        setShouldShowCertificate(true);
      }
    }
    try {
      setLoading(true);
      const requestBody = {
        action: 0,
        oid: config.aws_org_id,
        tenant: userDetails.uData.oid,
        topicid: courseDetails.tid,
        ur_id: userDetails.uData.ur_id,
        eid: userDetails.username,
        emailid: userDetails.email,
        schema: config.schema,
      };
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = `/listUserCerts?json=${encodeURIComponent(
        base64EncodedString
      )}`;
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: userDetails?.accessToken,
          },
        }
      );
      if (config.aws_org_id === "NSECORP") {
        generateCertificate(response);
      } else {
        setCertificate(true);
        setOpen(true);
        setCertificateList(response);
      }
      setLoading(false);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }
  async function generateCertificate(data) {
    setCertLoad(true);
    try {
      setLoading(true);
      const bodyParam = {
        body: {
          name:
            userDetails?.uData?.last_name === undefined ||
            userDetails?.uData?.last_name === null
              ? capitalize(userDetails?.uData?.first_name)
              : capitalize(userDetails?.uData?.first_name) +
                " " +
                capitalize(userDetails?.uData?.last_name),
          course_title: data.ttitle,
          oid: config.aws_org_id,
          course_id: data.tid,
          date: moment(data.compdate).format("DD/MM/YYYY"),
          uid: userDetails?.uData?.ur_id,
          cert_id: `23${
            data?.certificate_no
              ? data?.certificate_no.toString().padStart(2, "0")
              : "0"
          }${
            data?.certificateid
              ? data?.certificateid.toString().padStart(4, "0")
              : "0"
          }`,
          company_name: userDetails?.uData?.company_name || "-",
          lang_code: userDetails?.uData?.lang,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken,
        },
      };
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/generateCertONDC",
        bodyParam
      );
      setCertUrl(response.body);
      setCertificate(true);
      setOpen(true);
      setCertificateList(response);
      setCertLoad(false);
    } catch (error) {
      console.error(error);
    }
  }
  function SurveyValidation() {
    let surveyArr = [];
    for (var j = 0; j < questionsRef.current.length; j++) {
      if (
        questionsRef.current[j].opindex === undefined ||
        questionsRef.current[j].opindex === ""
      ) {
        setSurveyError("Some questions are not answered , Please answer");
        surveyArr.push(false);
      }
      surveyArr.push(true);
    }
    if (!surveyArr.includes(false)) {
      updateSurvey(curObject.oid);
      syncUserProgress();
    } else {
    }
  }
  async function updateSurvey(objid) {
    var obj = {};
    obj.survey = [];
    for (var l = 0; l < questionsRef.current.length; l++) {
      var robj = {};
      robj.iid = questionsRef.current[l].iid;
      robj.value = questionsRef.current[l].value;
      robj.opindex = questionsRef.current[l].opindex;
      robj.qtype = questionsRef.current[l].qtype;
      obj.survey.push(robj);
      if (l == questionsRef.current.length - 1) {
        try {
          setLoading(true);
          const jsonString = JSON.stringify({
            oid: config.aws_org_id,
            topicid: courseDetails.tid,
            eid: userDetails.username,
            emailid: userDetails.email,
            tenant: userDetails.uData.oid,
            pid: courseDetails.pid,
            objid,
            surveydata: obj,
            schema: config.schema,
          });
          const base64EncodedString = btoa(jsonString);
          const bodyParam = {
            body: {
              json: base64EncodedString,
            },
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: userDetails?.accessToken,
            },
          };
          await API.post(
            config.aws_cloud_logic_custom_name,
            "/updateUserSurvey",
            bodyParam
          );
        } catch (error) {
          console.error(error);
        }
      }
    }
  }
  async function syncUserProgress(type) {
    let coindex = curObjIndex;
    coindex++;
    setLoading(true);
    const bodydata = {
      oid: config.aws_org_id,
      status:
        courseDetails.freenavigation == "false" ||
        courseDetails.freenavigation == false
          ? "update"
          : "nav",
      tid: courseDetails.tid,
      id: userDetails.id,
      iid: config.aws_cognito_identity_pool_id,
      otype: curObject.otype,
      nugget: curNugIndex + 1,
      tnuggets: courseDetails.noofnuggets,
      nav:
        courseDetails.freenavigation == "false" ||
        courseDetails.freenavigation == false
          ? false
          : true,
      email: userDetails.username,
      emailid: userDetails.email,
      tenant: userDetails.uData.oid,
      tobjects: curNugget.objects.length,
      object: coindex,
      pid: courseDetails.pid,
      ur_id: userDetails.uData.ur_id,
      obj_id: curObject.oid,
      op: 2,
      key: courseDetails.tid,
      obtime: (new Date().getTime() - startTime) / 1000,
      rmc: curNugget.ntitle,
      cert: courseDetails.certification,
      schema: config.schema,
    };
    if (type !== undefined) {
      bodydata.op = 1;
    }
    const jsonString = JSON.stringify(bodydata);
    const utf8String = unescape(encodeURIComponent(jsonString));
    const base64EncodedString = btoa(utf8String);
    const bodyParam = {
      body: {
        json: base64EncodedString,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken,
      },
    };
    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/syncUserDataWeb",
        bodyParam
      );
      if (closePopUp.current === true) {
        setOpen(false);
        closePopUp.current = false;
      }
      let nrobj, prevobjtime, prevObject;
      if (type === undefined) {
        nrobj = courseResume;
        curNugget.objects[curObjIndex].op = 2;
        prevobjtime = curNugget.objects[curObjIndex].oduration;
        prevObject = curNugget.objects[curObjIndex].otype;
        setCurObjIndex(coindex);
        nrobj.robject = coindex;
        let ttp = totalProgress;
        ttp++;
        setTotalProgress(ttp);
      }
      setQuestion([]);
      setCurrentQuestion(0);
      questionsRef.current = [];
      setTimerPalyPause(false);
      setShowScore(false);
      setStartQuiz(false);
      setMarkComplete(false);
      if (coindex == curNugget.objects.length && type === undefined) {
        let curnugind = curNugIndex;
        setCurObjIndex(0);
        nrobj.robject = 0;
        curnugind++;
        setCurNugIndex(curnugind);
        if (courseDetails.nuggets[curnugind] !== undefined) {
          setCurNugget(courseDetails.nuggets[curnugind]);
          nrobj.rnugget = courseDetails.nuggets[curnugind].nid;
          if (courseDetails.nuggets[curnugind].objects[0].op == 0) {
            courseDetails.nuggets[curnugind].objects[0].op = 1;
          }
          let rmtime = { ...remaining };
          rmtime.mremaining = rmtime.mremaining - 1;
          rmtime.tremaining = rmtime.tremaining - prevobjtime;
          setRemaining(rmtime);
          setCurObject(courseDetails.nuggets[curnugind].objects[0]);
          if (
            courseDetails.ctype == "enforced" &&
            courseDetails.nuggets[curnugind].objects[0].op == 1
          ) {
            setProgress(
              courseDetails.nuggets[curnugind]?.objects[0]?.rtime ??
                courseDetails.nuggets[curnugind].objects[0].oduration
            );
            setTimerPalyPause(true);
          }
          if (
            (courseDetails.ctype == "enforced" &&
              courseDetails.nuggets[curnugind].objects[0].op == 1 &&
              courseDetails.nuggets[curnugind].objects[0].otype == "video") ||
            courseDetails.nuggets[curnugind].objects[0].otype == "quiz"
          ) {
            setTimerPalyPause(false);
          }
          if (
            courseDetails.nuggets[curnugind].objects[0].otype == "scorm" &&
            courseDetails.nuggets[curnugind].objects[0].op == 1
          ) {
            scormpPause.current = false;
            retake.current = false;
          } else {
            scormpPause.current = true;
          }
          setLoading(false);
        } else if (curnugind == courseDetails.nuggets.length) {
          // close popup
          let rmtime = { ...remaining };
          rmtime.mremaining = rmtime.mremaining - 1;
          rmtime.tremaining = rmtime.tremaining - prevobjtime;
          setRemaining(rmtime);
          updateCourseAnalytics(2);
          var tp = "2";
          let ttp = 0;
          for (var d = 0; d < courseDetails.nuggets.length; d++) {
            tp = tp + "-2";
            for (
              let index = 0;
              index < courseDetails.nuggets[d]?.objects.length;
              index++
            ) {
              ttp++;
            }
          }
          setTotalProgress(ttp);
          if (courseDetails.freenavigation) {
            rmtime.tremaining = 0;
            rmtime.mremaining = 0;
            var dataset = courseDetails;
            dataset.userdataset.userprogress =
              dataset.userdataset.userprogress !== undefined
                ? dataset.userdataset.userprogress
                : {};
            setCourseDetails(dataset);
          }
          setTpProgress(tp);
          if (
            prevObject !== "quiz" &&
            (courseDetails.certification === "true" ||
              courseDetails.certification === true)
          ) {
            syncUserCertificate("");
          } else if (
            prevObject === "quiz" &&
            (courseDetails.certification === "true" ||
              courseDetails.certification === true)
          ) {
            nextCourseref.current = true;
            syncUserCertificate("quiz");
          } else {
            await nextcourse();
            setOpen(false);
            setLoading(false);
          }
        }
      } else if (type === undefined) {
        if (curNugget.objects[coindex].op == 0) {
          curNugget.objects[coindex].op = 1;
        }
        let rmtime = { ...remaining };
        rmtime.tremaining = rmtime.tremaining - prevobjtime;
        rmtime.mremaining = remaining.mremaining;
        setRemaining(rmtime);
        setCurObject(curNugget.objects[coindex]);
        if (
          courseDetails.ctype == "enforced" &&
          curNugget.objects[coindex].op == 1 &&
          curNugget.objects[coindex].otype != "feedback"
        ) {
          setProgress(
            curNugget?.objects[coindex]?.rtime ??
              curNugget?.objects[coindex]?.oduration
          );
          setTimerPalyPause(true);
        }
        if (
          (courseDetails.ctype == "enforced" &&
            curNugget.objects[coindex].op == 1 &&
            curNugget.objects[coindex].otype == "video") ||
          curNugget.objects[coindex].otype == "quiz"
        ) {
          setTimerPalyPause(false);
        }
        if (
          curNugget.objects[coindex].op == 1 &&
          curNugget.objects[coindex].otype == "scorm"
        ) {
          scormpPause.current = false;
          retake.current = false;
        } else {
          scormpPause.current = true;
        }
        if (curNugget.objects[coindex].otype == "feedback") {
          questionsRef.current = [];
          setTimerPalyPause(false);
          setQuestion([]);
          setCurrentQuestion(0);
          getQuiz(curNugget.objects[coindex]);
          setLoading(true);
        } else {
          setLoading(false);
        }
      }
      if (type === undefined) {
        setcourseResume(nrobj);
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function syncUserProgresswhilequiz(score, squiz, questionsJSON) {
    let obj = {};
    obj.json = questionsJSON;
    try {
      questionsRef.current = [];
      let coindex = curObjIndex;
      coindex++;
      setLoading(true);
      let bodyParam2 = {
        oid: config.aws_org_id,
        status:
          courseDetails.freenavigation == "false" ||
          courseDetails.freenavigation == false
            ? "update"
            : "nav",
        key: courseDetails.tid,
        topicid: courseDetails.tid,
        id: userDetails.id,
        iid: config.aws_cognito_identity_pool_id,
        otype: curObject.otype,
        nugget: curNugIndex + 1,
        tnuggets: courseDetails.noofnuggets,
        nav:
          courseDetails.freenavigation == "false" ||
          courseDetails.freenavigation == false
            ? false
            : true,
        email: userDetails.username,
        emailid: userDetails.email,
        tenant: userDetails.uData?.oid,
        tobjects: curNugget.objects.length,
        object: coindex,
        pid: courseDetails.pid,
        cert: courseDetails.certification,
        ur_id: userDetails.uData.ur_id,
        obj_id: curObject.oid,
        op: 2,
        score,
        quizJSON: obj,
        obtime: (new Date().getTime() - startTime) / 1000,
        schema: config.schema,
      };
      if (score) {
        bodyParam2.score = score;
      }
      if (config.aws_org_id === "EXCELEARN") {
        bodyParam2.op =
          parseInt((score / questionsJSON?.quiz_length) * 100) >=
          questionsJSON?.cutoff
            ? 2
            : 1;
      }
      const jsonString = JSON.stringify(bodyParam2);
      const utf8String = unescape(encodeURIComponent(jsonString));
      const base64EncodedString = btoa(utf8String);
      const bodyParam = {
        body: {
          json: base64EncodedString,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken,
        },
      };
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/syncUserDataWeb",
        bodyParam
      );
      let nrobj = courseResume;
      curNugget.objects[curObjIndex].op = 2;
      let prevobjtime = curNugget.objects[curObjIndex].oduration;
      let prevObject = curNugget.objects[curObjIndex].otype;
      nrobj.robject = coindex;
      let ttp = totalProgress;
      ttp++;
      setTotalProgress(ttp);
      setMarkComplete(false);
      if (coindex == curNugget.objects.length) {
        let curnugind = curNugIndex;
        nrobj.robject = 0;
        curnugind++;
        if (courseDetails.nuggets[curnugind] !== undefined) {
          nrobj.rnugget = courseDetails.nuggets[curnugind].nid;
          if (courseDetails.nuggets[curnugind].objects[0].op == 0) {
            courseDetails.nuggets[curnugind].objects[0].op = 1;
          }
          let rmtime = { ...remaining };
          rmtime.mremaining = rmtime.mremaining - 1;
          rmtime.tremaining = rmtime.tremaining - prevobjtime;
          setRemaining(rmtime);
        } else if (curnugind == courseDetails.nuggets.length) {
          // close popup
          let rmtime = { ...remaining };
          rmtime.mremaining = rmtime.mremaining - 1;
          rmtime.tremaining = rmtime.tremaining - prevobjtime;
          updateCourseAnalytics(2);
          setRemaining(rmtime);
          var tp = "2";
          for (var d = 0; d < courseDetails.nuggets.length; d++) {
            tp = tp + "-2";
          }
          setTpProgress(tp);
          if (
            !squiz &&
            prevObject !== "quiz" &&
            (courseDetails.certification === "true" ||
              courseDetails.certification === true)
          ) {
            syncUserCertificate("");
            setOpen(false);
          } else if (
            !squiz &&
            prevObject === "quiz" &&
            (courseDetails.certification === "true" ||
              courseDetails.certification === true)
          ) {
            nextCourseref.current = true;
            syncUserCertificate("quiz");
          } else if (!squiz) {
          } else {
            setLoading(false);
            setIsLoading(false);
          }
        }
      } else {
        if (curNugget.objects[coindex].op == 0) {
          curNugget.objects[coindex].op = 1;
        }
        let rmtime = remaining;
        rmtime.tremaining = rmtime.tremaining - prevobjtime;
        rmtime.mremaining = remaining.mremaining;
        setRemaining(rmtime);
      }
      setcourseResume(nrobj);
      setLoading(false);
      setShowScore(true);
    } catch (error) {
      console.error(error);
    }
  }
  async function updateUserScormAnalytics(rec_op) {
    try {
      let bodydata = {
        oid: config.aws_org_id,
      };
      bodydata = window.cudetails;
      bodydata.atype = 1;
      bodydata.ur_id = userDetails.uData.ur_id;
      bodydata.obj_id = curObject.oid;
      bodydata.cid = courseDetails.tid;
      bodydata.op = rec_op;
      bodydata.susdt = window.cudetails.susdt;
      bodydata.schema = config.schema;
      const jsonString = JSON.stringify(bodydata);
      const base64EncodedString = btoa(jsonString);
      const bodyParam = {
        body: { json: base64EncodedString },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken,
        },
      };
      await API.post(
        config.aws_cloud_logic_custom_name,
        `/updateUserScormAnalytics`,
        bodyParam
      );
    } catch (error) {
      console.error(error);
    }
  }
  async function updateResumeTime() {
    try {
      var obj = {};
      obj.oid = curObject.oid;
      if (curObject.otype == "video" && reactplayer.current !== null) {
        obj.time = Math.round(reactplayer.current.getCurrentTime());
      }
      obj.obtime = progress;
      var dataset = courseDetails;
      dataset.userdataset.tresume = obj;
      setCourseDetails(dataset);
      const jsonString = JSON.stringify({
        oid: config.aws_org_id,
        obj_id: curObject.oid,
        op: 1,
        tid: courseDetails.tid,
        ur_id: userDetails.uData.ur_id,
        key: courseDetails.tid,
        obtime:
          obj.time !== undefined
            ? obj.time
            : (new Date().getTime() - startTime) / 1000,
        tenant: userDetails.uData?.oid,
        eid: userDetails.username,
        tresume: obj,
        nid: curNugget.nid,
        schema: config.schema,
      });
      const utf8String = unescape(encodeURIComponent(jsonString));
      const base64EncodedString = btoa(utf8String);
      const bodyParam = {
        body: {
          json: base64EncodedString,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken,
        },
      };
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/syncUserDataWeb",
        bodyParam
      );
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  }
  async function downloadPdf() {
    try {
      let filename,
        type,
        durl = "";
      if (curObject.otype == "pdf") {
        type = "application/pdf";
        filename = curObject.otitle + ".pdf";
        durl = curObject.ourl;
      }
      if (curObject.dtype !== undefined && curObject.dtype.includes("ppt")) {
        type = "application/pdf";
        filename = curObject.otitle + curObject.dtype;
        durl = curObject.durl;
      } else if (
        curObject.dtype !== undefined &&
        curObject.dtype.includes("doc")
      ) {
        type =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        filename = curObject.otitle + curObject.dtype;
        durl = curObject.durl;
      }
      axios
        .get(durl, {
          responseType: "blob",
          method: "GET",
          headers: {
            "Access-Control-Allow-Origin": config.DOMAIN,
            "Access-Control-Request-Headers": "Content-Type, Authorization",
            "Content-Type": type,
          },
        })
        .then((res) => {
          let file = new Blob([res.data], { type: type });
          saveAs(file, filename);
        });
    } catch (error) {}
  }
  async function downloadScorm() {
    try {
      let filename,
        type,
        durl = "";
      if (curObject.otype == "scorm") {
        type = "application/zip";
        filename = curObject.otitle + ".zip";
        durl = `${
          config.aws_cloudfront_url
        }${config.aws_org_id.toLowerCase()}-content/${
          courseDetails?.tid
        }/html/${curObject?.oid}/${curObject?.oid}.zip`;
      }
      await axios
        .get(durl, {
          responseType: "blob",
          method: "GET",
          headers: {
            "Access-Control-Allow-Origin": config.DOMAIN,
            "Access-Control-Request-Headers": "Content-Type, Authorization",
            "Content-Type": type,
          },
        })
        .then((res) => {
          let file = new Blob([res.data], { type: type });
          saveAs(file, filename);
        });
    } catch (error) {}
  }
  async function passFail(courseDetails) {
    const status = await new Promise((resolve) => {
      const qinfoTemp = [];
      courseDetails.nuggets.forEach((nugget) => {
        if (Array.isArray(nugget.objects)) {
          nugget.objects.forEach((item) => {
            let qscore;
            if (item.otype === "quiz") {
              if (item.score) {
                qscore = parseInt(item.score[item?.score?.length - 1], 10);
                const qreport =
                  (qscore / item.quiz_length) * 100 >= parseInt(item.cutoff, 10)
                    ? "pass"
                    : "fail";
                qinfoTemp.push(qreport);
              }
            }
          });
        }
      });
      const hasFail = qinfoTemp.some((report) => report === "fail");
      resolve(hasFail);
    });
    return status;
  }
  useEffect(() => {
    const interval = setInterval(() => {
      if (window?.API?.LMSGetValue("cmi.core.lesson_status") !== undefined) {
        if (
          window?.API?.LMSGetValue("cmi.core.lesson_status") === "completed" ||
          window?.API?.LMSGetValue("cmi.core.lesson_status") === "passed"
        ) {
          setScorm_next_btn(true);
          Window.scorm_nextbtn = true;
        } else {
          setScorm_next_btn(false);
          Window.scorm_nextbtn = false;
        }
      } else {
        setScorm_next_btn(false);
        Window.scorm_nextbtn = false;
      }
    }, 2000);
    return () => clearInterval(interval);
  });
  async function checkConditions() {
    if (config.aws_org_id === "EXCELEARN") {
      const passStatus = await passFail(courseDetails);
      if (passStatus) {
        setShouldShowCertificate(false);
        showAlert();
      } else {
        setShouldShowCertificate(true);
      }
    } else {
      setShouldShowCertificate(true);
    }
  }

  const showAlert = () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      width: 400,
      text:
        "Oh, you're unable to complete the course because you failed in the assessment",
    }).then(() => {
      history.push("/home"); // Redirect to home page
    });
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        disableEscapeKeyDown
        closeAfterTransition
        BackdropComponent={Backdrop}
        tabIndex={1}
        BackdropProps={{
          timeout: 500,
        }}
      >
        {loading ? (
          <>
            <CircularProgress
              className="spinnerStyle"
              style={{
                position: "absolute !important",
                left: "50%",
                top: "50%",
              }}
            />
          </>
        ) : (
          <>
            {certificate ? (
              <>
                <div style={{ display: "flex" }}>
                  <button
                    onClick={handleModalClose}
                    className={classes.closeButton}
                  >
                    <CloseIcon
                      fontSize="large"
                      style={{ fontSize: "2.4rem" }}
                    />
                  </button>
                </div>
                {shouldShowCertificate ? (
                  <CertificatePopUp
                    setOpen={setCertificate}
                    open={certificate}
                    sData={courseDetails}
                    modalPopUpClose={setOpen}
                    courseDetails={courseDetails}
                    certificateList={certificateList}
                    handleModalClose={handleModalClose}
                    handleOpenFeedback={handleOpenFeedback}
                    openFeedback={openFeedback}
                    setOpenFeedback={setOpenFeedback}
                    CheckFeedbackSubmitted={CheckFeedbackSubmitted}
                    setFeedbackSubmitted={setFeedbackSubmitted}
                    feedbackSubmitted={feedbackSubmitted}
                    certUrl={certUrl}
                    certLoad={certLoad}
                  />
                ) : null}
              </>
            ) : (
              <Fade in={open}>
                <div
                  className={classes.modalSection}
                  style={{ position: "relative" }}
                >
                  <div className={classes.headerSection}>
                    <h3
                      id="transition-modal-title"
                      className={classes.mainHeader}
                    >
                      {certificate ? "" : curObject.otitle1}
                      {curObject.otype == "quiz" ? (
                        <>
                          {question.length !== 0 && question !== undefined ? (
                            <>
                              <span className={classes.separator}></span>
                              <HelpOutlineRoundedIcon
                                style={{
                                  fontSize: "2rem",
                                  margin: "0rem 0rem 0rem 1rem",
                                }}
                              />
                              <div className={classes.totalQuestions}>
                                {question.length}
                              </div>
                            </>
                          ) : null}
                        </>
                      ) : (
                        <></>
                      )}
                      {curObject.otype == "video" ? (
                        <Tippy
                          {...defaultTippyProps}
                          content={
                            <div
                              style={{
                                background: "transparent",
                                color: "#555555",
                                padding: ".5rem 1rem 1rem 1rem",
                                cursor: "pointer",
                                fontSize: "1.60rem",
                                fontWeight: "400",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: curObject.odescription
                                  ? curObject.odescription
                                  : "No description",
                              }}
                            ></div>
                          }
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            <span className={classes.barResponsive}></span>
                            <button
                              className={classes.infoResponsive}
                              title="Information tooltip"
                            >
                              <InfoOutlinedIcon
                                className="icon"
                                style={{
                                  display: "inline-block",
                                  color: "#1974b5",
                                }}
                              />
                            </button>
                            {markComplete &&
                            curObject.otype != "quiz" &&
                            ((curObjIndex != curNugget.objects.length &&
                              curNugIndex != courseDetails.nuggets.length) ||
                              curObject.op == 1) ? (
                              <>
                                <div>
                                  <Button
                                    variant="outlined"
                                    className={
                                      curObject.otype == "video"
                                        ? "markcompleteHeader"
                                        : "markcompleteHeader"
                                    }
                                    onClick={() => {
                                      curObject.op == 1
                                        ? handlemarkcomplete()
                                        : nextobject();
                                    }}
                                  >
                                    {" "}
                                    {curObjIndex != curNugget.objects.length &&
                                    curNugIndex != courseDetails.nuggets.length
                                      ? `${userDetails?.languagejson
                                          ?.Course_View?.Next || "Next"}`
                                      : "Mark complete"}
                                  </Button>
                                </div>
                              </>
                            ) : (
                              <> </>
                            )}
                          </div>
                        </Tippy>
                      ) : (
                        <>
                          {config.aws_org_id === "SPANDANA" ? (
                            <></>
                          ) : curObject.otype === "pdf" ? (
                            <button
                              to="#"
                              title="Download Button"
                              onClick={() => downloadPdf()}
                              className={classes.downloadIcon}
                            >
                              {!certificate ? (
                                <img
                                  src={downloadPdfSvg}
                                  alt="download"
                                  height="100%"
                                />
                              ) : (
                                ""
                              )}
                            </button>
                          ) : curObject.otype === "scorm" &&
                            config.aws_org_id.toLocaleLowerCase() !==
                              "nseacademy" ? (
                            <button
                              to="#"
                              title="Download Button"
                              onClick={() => downloadScorm()}
                              className={classes.downloadIcon}
                            >
                              {!certificate ? (
                                <img
                                  src={downloadPdfSvg}
                                  alt="download"
                                  height="100%"
                                />
                              ) : (
                                ""
                              )}
                            </button>
                          ) : null}
                        </>
                      )}
                      {courseDetails.ctype == "regular" ? (
                        <></>
                      ) : (
                        <>
                          {loading ? (
                            <></>
                          ) : (
                            <>
                              {timerPalyPause &&
                                progress > 0 &&
                                curObject.op === 1 &&
                                curObject.otype !== "feedback" &&
                                curObject.otype !== "quiz" && (
                                  <>
                                    <div
                                      className="timerdiv"
                                      style={{ width: "125px" }}
                                    >
                                      <Box
                                        alignItems="center"
                                        justifyContent="center"
                                      >
                                        <Typography
                                          variant="caption"
                                          component="div"
                                          color="textSecondary"
                                          style={{
                                            fontSize: "1.5rem",
                                            color: "#000",
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          {" "}
                                          <TimerIcon
                                            style={{
                                              fontSize: "2.3rem",
                                              marginLeft: ".5rem",
                                            }}
                                          />{" "}
                                          {formatTime(progress)}
                                        </Typography>
                                      </Box>
                                    </div>
                                  </>
                                )}
                            </>
                          )}
                        </>
                      )}
                    </h3>
                    <Typography></Typography>
                    <button
                      onClick={handleModalClose}
                      className={classes.closeResponsive}
                      title="Close"
                    >
                      <CloseIcon
                        fontSize="large"
                        style={{ fontSize: "2.4rem" }}
                      />
                    </button>
                  </div>
                  {loading ? (
                    <>
                      <CircularProgress
                        className="spinnerStyle"
                        style={{
                          position: "absolute !important",
                          left: "50%",
                          top: "50%",
                        }}
                      />
                    </>
                  ) : (
                    <>
                      {scormnExtBtn &&
                      curObjIndex != curNugget.objects.length &&
                      curNugIndex != courseDetails.nuggets.length ? (
                        <>
                          <div>
                            <Button
                              style={{
                                position: "absolute",
                                boxShadow:
                                  "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                color: "#1C6D9C",
                                fontWeight: "600",
                                zIndex: "999",
                              }}
                              variant="outlined"
                              className={
                                curObject.otype == "video"
                                  ? "markcomplete"
                                  : "markcomplete  docmarkcombtn"
                              }
                              onClick={() => {
                                let prevobjtime;
                                curNugget.objects[curObjIndex].op = 2;
                                prevobjtime =
                                  curNugget.objects[curObjIndex].oduration;
                                let ttp = totalProgress;
                                let rmtime = { ...remaining };
                                rmtime.mremaining = rmtime.mremaining - 1;
                                rmtime.tremaining =
                                  rmtime.tremaining - prevobjtime;
                                setRemaining(rmtime);
                                ttp++;
                                setTotalProgress(ttp);
                                nextobject();
                                setScormnExtBtn(false);
                              }}
                            >
                              {" "}
                              {`${userDetails?.languagejson?.Course_View
                                ?.Next || "Next"}
                            `}
                            </Button>
                          </div>
                        </>
                      ) : (
                        <> </>
                      )}
                      <>
                        {markComplete && curObject.otype != "quiz" ? (
                          <>
                            <div>
                              {curObject.otype !== "scorm" &&
                              window.scorm_btn !== true ? (
                                <Stack spacing={19} direction="row">
                                  <Button
                                    variant="contained"
                                    className={
                                      curObject.otype === "video"
                                        ? "markcomplete displayNoneMobile"
                                        : "markcomplete docmarkcombtn"
                                    }
                                    onClick={() => {
                                      if (
                                        curObject.op === 1 ||
                                        curObject.op === 0
                                      ) {
                                        handlemarkcomplete();
                                      } else {
                                        nextobject();
                                        checkConditions();
                                      }
                                    }}
                                  >
                                    {curObjIndex !== curNugget.objects.length &&
                                    curNugIndex !== courseDetails.nuggets.length
                                      ? `${userDetails?.languagejson
                                          ?.Course_View?.Next || "Next"}`
                                      : "Mark complete"}
                                  </Button>
                                  {curObject?.otype === "pdf" &&
                                    curObject.ext_url_type === true && (
                                      <Button
                                        variant="contained"
                                        className={
                                          curObject.otype === "video"
                                            ? "markcomplete displayNoneMobile"
                                            : "markcomplete docmarkcombtn"
                                        }
                                        onClick={() => {
                                          let url = curObject?.ext_url || "";

                                          if (
                                            !url.startsWith("http://") &&
                                            !url.startsWith("https://")
                                          ) {
                                            url = `https://${url}`;
                                          }

                                          window.open(url, "_blank");
                                        }}
                                      >
                                        {" "}
                                        Practice Now
                                      </Button>
                                    )}
                                </Stack>
                              ) : null}
                            </div>
                            {curObject.otype === "scorm" &&
                              (window.scorm_nextbtn === true ||
                                scorm_next_btn) && (
                                <Button
                                  style={{
                                    position: "absolute",
                                    boxShadow:
                                      "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                    color: "#1C6D9C",
                                    fontWeight: "600",
                                    zIndex: "999",
                                  }}
                                  className={
                                    curObject.otype === "video"
                                      ? "markcomplete displayNoneMobile"
                                      : "markcomplete docmarkcombtn"
                                  }
                                  variant="outlined"
                                  onClick={() => {
                                    window.scorm_nextbtn = false;
                                    setScorm_next_btn(false);
                                    if (
                                      curObject.op === 1 ||
                                      curObject.op === 0
                                    ) {
                                      handlemarkcomplete();
                                    } else {
                                      nextobject();
                                    }
                                  }}
                                >
                                  {" "}
                                  {curObjIndex !== curNugget.objects.length &&
                                  curNugIndex !== courseDetails.nuggets.length
                                    ? `${userDetails?.languagejson?.Course_View
                                        ?.Next || "Next"}`
                                    : "Mark complete"}
                                </Button>
                              )}
                            <div>
                              <FullscreenIcon
                                onClick={handle.enter}
                                style={{
                                  fontSize: "2.5rem",
                                  position: "absolute",
                                  right: "1rem",
                                  bottom: "3rem",
                                }}
                              />
                            </div>
                          </>
                        ) : (
                          <> </>
                        )}
                        {renderObjects()}
                      </>
                      {/* )} */}
                    </>
                  )}
                </div>
              </Fade>
            )}
          </>
        )}
      </Modal>
    </div>
  );
}
export function RatingPopUp({ courseDetails }) {
  let userDetails = useSelector(authData);
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0, 0, 0, 0.73)",
      overflow: "auto",
    },
    modalSection: {
      backgroundColor:
        userDetails?.themeDark === "dark"
          ? "#272C38"
          : theme.palette.background.paper,
      color:
        userDetails?.themeDark === "dark"
          ? theme.palette.background.paper
          : "black",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      height: "100%",
      borderRadius: ".8rem",
      overflowX: "hidden",
      overflowY: "auto",
      "@media (max-width: 500px)": {
        width: "90%!important",
      },
      "@media (max-width: 770px)": {
        padding: "1rem",
      },
    },
    paper1: {
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "400px",
      backgroundColor:
        userDetails?.themeDark === "dark"
          ? "#272C38"
          : theme.palette.background.paper,
      color:
        userDetails?.themeDark === "dark"
          ? theme.palette.background.paper
          : "black",
      borderRadius: ".8rem",
      "& > div > button > svg": {
        color:
          userDetails?.themeDark === "dark"
            ? theme.palette.background.paper
            : "black",
      },
      "@media (max-width: 430px)": {
        width: "90%",
      },
    },
    ratingroot: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      gap: "1.5rem",
      "& > span": {
        margin: 0,
      },
    },
    quizroot: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
    },
    MuiGriditem: {
      padding: "0 12px",
    },
    quizpaper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    certcover: {
      height: "100%",
    },
    overlay: {
      backgroundImage:
        "linear-gradient(to right bottom, rgba(0,0,0,.2), rgba(0,0,0,.2))",
      width: "1100px",
      height: "660px",
    },
    label: {
      "& .MuiTypography-body1": {
        fontSize: "1.3rem",
        color: "#555555",
        fontWeight: "500",
        overflow: "hidden !important",
      },
    },
    labelSurvey: {
      "& .MuiTypography-body1": {
        fontSize: "1.25rem",
        color: "#000",
        fontWeight: "600",
        overflow: "hidden !important",
      },
      "& .MuiTypography-body1::after": {
        content: "*",
        color: "red",
      },
    },
    label2: {
      "&.MuiFormControlLabel-root": {
        paddingLeft: "30px",
        display: "flex",
        flexDirection: "column",
      },
      "&.MuiTypography-body1": {
        fontSize: "1.3rem",
      },
    },
    contentType: {
      "& .PrivateSwitchBase-input-118": {
        "& ::after": {
          content: "hi !important",
        },
      },
    },
    barResponsive: {
      height: "25px",
      borderRight: "2px solid #000",
      marginLeft: "2rem",
      "@media (max-width: 500px)": {
        display: "none",
      },
    },
    infoResponsive: {
      display: "flex",
      height: "inherit",
      margin: "0 2rem",
      background: "transparent",
      border: "none",
      "@media (max-width: 500px)": {
        margin: "0rem 0.5rem",
      },
      "&:focus": {
        border: "2px solid black",
      },
    },
    closeResponsive: {
      border: "none",
      background: "transparent",
      cursor: "pointer",
      display: "flex",
      marginRight: "-20px",
      color:
        userDetails?.themeDark === "dark"
          ? theme.palette.background.paper
          : "black",
      "@media (max-width: 770px)": {
        marginRight: 0,
      },
      "&:focus": {
        border: "2px solid black",
      },
    },
    headerSection: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    mainHeader: {
      display: "flex",
      flexWrap: "wrap",
      fontWeight: "600",
      alignItems: "center",
      paddingRight: "4rem",
    },
    documentSection: {
      marginTop: "10px",
      width: "95%",
      height: "-webkit-fill-available",
      position: "absolute",
      marginBottom: "6rem",
      "@media (max-width: 330px)": {
        width: "100%",
      },
    },
    downloadIcon: {
      position: "absolute",
      right: "55px",
      background: "transparent",
      border: "none",
      height: "2.3rem",
      width: "2.3rem",
      marginLeft: "2rem",
      cursor: "pointer",
      zIndex: "10000",
      "@media (max-width: 400px)": {
        right: "40px",
      },
    },
    separator: {
      height: "27px",
      borderRight:
        userDetails?.themeDark === "dark"
          ? "2px solid lightgray"
          : "2px solid #333333",
      marginLeft: "2rem",
      "@media (max-width: 500px)": {
        display: "none",
      },
    },
    videoContainer: {
      display: "contents",
    },
    videoInnerContainer: {
      width: "90%!important",
      height: "88%!important",
      margin: "2rem auto 0rem!important",
      "@media (max-width: 700px)": {
        width: "100%!important",
        height: "70%!important",
      },
    },
    rateCourseButton: {
      fontSize: "1.5rem",
      fontWeight: "600",
      lineHeight: "1.6rem",
      backgroundColor: "white!important",
      "&:focus": {
        border: "2px solid black",
        color: "black",
      },
    },
    rateCourseSubmitButton: {
      fontWeight: "600",
      fontFamily: `myfontregular`,
      "&:focus": {
        border: "2px solid black",
        backgroundColor: "black",
        color: "white",
      },
    },
    pdfContainer: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      height: "88%",
      overflowX: "hidden",
      position: "relative",
      marginTop: "1rem",
      "& > div": {
        width: "100%",
        height: "100%",
        "& > iframe": {
          width: "100%",
          height: "100%",
          backgroundColor: "white",
        },
      },
    },
    feedbackModalStyle: {
      boxShadow: theme.shadows[5],
      width: "80vw",
      height: "80vh",
      overflowY: "auto",
      overflowX: "hidden",
      backgroundColor:
        userDetails?.themeDark === "dark"
          ? "#272C38"
          : theme.palette.background.paper,
      color:
        userDetails?.themeDark === "dark"
          ? theme.palette.background.paper
          : "black",
      borderRadius: ".8rem",
      "@media (max-width: 430px)": {
        width: "90%",
      },
    },
    feedbackHeaderSection: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "2rem",
      padding: "16px 20px 24px",
      zIndex: "99",
      width: "80vw",
      position: "fixed",
      boxShadow:
        "rgba(50,50,93,0.25) 0px 6px 12px -2px, rgba(0,0,0,0.3) 0px 3px 7px -3px",
      backgroundColor:
        userDetails?.themeDark === "dark"
          ? "#272C38"
          : theme.palette.background.paper,
      "@media (max-width: 430px)": {
        width: "90%",
        padding: "16px 12px 4px",
      },
    },
    feedbackCloseButton: {
      border: "none",
      background: "transparent",
      cursor: "pointer",
      color:
        userDetails?.themeDark === "dark"
          ? theme.palette.background.paper
          : "black",
    },
    feedbackTextarea: {
      width: "80%",
      fontSize: "1.5rem!important",
      margin: "3rem auto",
      backgroundColor:
        userDetails?.themeDark === "dark"
          ? "black!important"
          : theme.palette.background.paper,
      color: userDetails?.themeDark === "dark" ? "white!important" : "black",
    },
    feedbackSlider: {
      textAlign: "center",
      width: "70%!important",
      "& > span": {
        fontSize: "1.2rem",
        color: userDetails?.themeDark === "dark" ? "white!important" : "auto",
      },
    },
    feedbackSubHeading: {
      textAlign: "left",
      margin: "5rem 0rem 3rem",
    },
    feedbackLabel: {
      fontSize: "1.2rem",
      fontWeight: "600",
    },
    feedbackLabelSection: {
      display: "flex",
      justifyContent: "space-between",
      width: "79%",
      margin: "auto",
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = React.useState(1);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    CheckFeedbackSubmitted();
  }, []);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  async function handleRateSubmit() {
    setLoading(true);
    const jsonString = JSON.stringify({
      emailid: userDetails.email,
      rating_date: 1,
      id: userDetails.id,
      iid: config.aws_cognito_identity_pool_id,
      tid: courseDetails.tid,
      email: userDetails.username,
      rating: value,
      oid: config.aws_org_id,
      uname: userDetails.name,
      tenant: userDetails.uData.oid,
      ur_id: userDetails.uData.ur_id,
      schema: config.schema,
    });
    const base64EncodedString = btoa(jsonString);
    const bodyParam = {
      body: {
        json: base64EncodedString,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken,
      },
    };
    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateTopicReport",
        bodyParam
      );
      history.push(`/course?id=${courseDetails.tid}`);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  }
  // Feedback form Modal function
  async function CheckFeedbackSubmitted() {
    setLoading(true);
    try {
      const requestBody = {
        tid: courseDetails.tid,
        oid: config.aws_org_id,
        ur_id: userDetails.uData.ur_id,
        schema: config.schema,
      };
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = `/CheckFeedbackSubmitted?json=${encodeURIComponent(
        base64EncodedString
      )}`;
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: userDetails?.accessToken,
          },
        }
      );
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }
  const [openFeedback, setOpenFeedback] = useState(false);
  const handleOpenFeedback = () => {
    setOpenFeedback(true);
  };
  // Feedback form Modal ends
  return (
    <div style={{ display: "flex", gap: "1.5rem" }}>
      {courseDetails.userdataset.userdata == true &&
      courseDetails.userdataset.star !== undefined ? (
        <></>
      ) : (
        <>
          <Button
            variant="outlined"
            color="primary"
            className={classes.rateCourseButton}
            onClick={handleOpen}
          >
            {" "}
            {`${userDetails?.languagejson?.Course_View?.RATE_COURSE ||
              "Rate Course"}`}
          </Button>
        </>
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper1}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" id="transition-modal-title"></Typography>
              <button
                onClick={handleClose}
                style={{
                  border: "none",
                  background: "transparent",
                  cursor: "pointer",
                }}
              >
                <CloseIcon fontSize="large" />
              </button>
            </div>
            <div className={classes.ratingroot}>
              <Typography
                variant="h6"
                style={{
                  fontSize: "2.5rem",
                  textAlign: "center",
                  fontFamily: `myfontregular`,
                }}
                id="transition-modal-title"
              >
                {`${userDetails?.languagejson?.Course_View?.Course_completed ||
                  "Course completed!"}`}
              </Typography>
              <Rating
                name="hover-feedback"
                icon={<StarRoundedIcon style={{ fontSize: "5rem" }} />}
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
              />
              {value == null && (
                <Box
                  ml={2}
                  style={{
                    color: "red",
                    fontSize: "12px",
                    width: "100%",
                    marginLeft: "100px",
                    fontFamily: `myfontregular`,
                  }}
                >
                  You must select a rating before you can click Submit
                </Box>
              )}
              {loading ? (
                <StyledButton>Loading..</StyledButton>
              ) : (
                <StyledButton
                  className={classes.rateCourseSubmitButton}
                  onClick={() => {
                    if (value !== null) {
                      handleRateSubmit();
                    }
                  }}
                >
                  {`${userDetails?.languagejson?.Course_View?.Submit ||
                    "Submit"}`}
                </StyledButton>
              )}
            </div>
          </div>
        </Fade>
      </Modal>
      <FeedbackPopUp
        courseDetails={courseDetails}
        handleOpenFeedback={handleOpenFeedback}
        openFeedback={openFeedback}
        setOpenFeedback={setOpenFeedback}
        CheckFeedbackSubmitted={CheckFeedbackSubmitted}
      />
    </div>
  );
}
